import { ACTION_CONST } from '../action.constants';

import OAUTH2_GRANT_TYPES from '../../constants/main.oauth2-grant-types';

const MainConfigConstants = {
  DEBUG: false,
  PROXY: '',
  API: {
    BASE: '/frontend-api-v2/',
    AUTH_SERVER_URL: '/api-auth-server',
    OAUTH2: {
     REQUESTS: {
       AUTHORIZATION: {
         URL_APPENDIX: '/compax/oauth/authorize'
       },
       LOGOUT: {
         URL_APPENDIX: '/logout'
       },
       TOKEN: {
         URL_APPENDIX: '/oauth/token'
       },
       NEW_PASSWORD: {
         URL_APPENDIX: '/passwordConfirmation'
       },
     },
    },
    REQUESTS: {
      [ACTION_CONST.API_GET_USER]: {
        URL_APPENDIX: `/user`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_USER,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
        USE_AUTH_SERVER: true,
      },
      [ACTION_CONST.API_SET_PASSWORD]: {
        URL_APPENDIX: `/user/set-password`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_SET_PASSWORD,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.CLIENT_CREDENTIALS,
        USE_AUTH_SERVER: true,
      },
      [ACTION_CONST.API_CHANGE_PASSWORD]: {
        URL_APPENDIX: `/user/change-password`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_CHANGE_PASSWORD,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
        USE_AUTH_SERVER: true,
      },
      [ACTION_CONST.API_POST_RESET_PASSWORD]: {
        URL_APPENDIX: `/user/resetPassword?userLogin={userLogin}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_RESET_PASSWORD,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.CLIENT_CREDENTIALS
      },
      [ACTION_CONST.API_GET_CUSTOMER]: {
        URL_APPENDIX: `/customers`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_CUSTOMER,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_CONTRACTS]: {
        URL_APPENDIX: `/customers/contracts`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_CONTRACTS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_MAIN_CONTRACTS]: {
        URL_APPENDIX: `/customers/contracts?onlyMain=true&customerId={selectedPartnerCustomerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_MAIN_CONTRACTS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_CONTRACTS_CHARGE_SUM]: {
        URL_APPENDIX: `/customers/contracts/totalPrice`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_CONTRACTS_CHARGE_SUM,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_ACCOUNTS]: {
        URL_APPENDIX: `/customers/account?expanded=true`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_ACCOUNTS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_CUSTOMER_BANK_ACCOUNT]: {
        URL_APPENDIX: `/customers/account/bankAccount`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_CUSTOMER_BANK_ACCOUNT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_ENV_TYPE]: {
        URL_APPENDIX: `/customers/account/{accountId}/evn/type`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_ENV_TYPE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_ENV_TYPE]: {
        URL_APPENDIX: `/customers/account/{accountId}/evn/type?anonymized={type}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_ENV_TYPE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PACKAGES]: {
        URL_APPENDIX: `/customers/contracts/{contractId}/minutesPackages?customerId={selectedPartnerCustomerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PACKAGES,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNERS]: {
        URL_APPENDIX: `/customers/partners`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNERS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_ADDRESSES]: {
        URL_APPENDIX: `/customers/addresses?expanded=true&language={language}&customerId={selectedPartnerCustomerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_ADDRESSES,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_SIPTRUNK]: {
        URL_APPENDIX: `/customers/contracts/{contractId}/sipTrunk?expanded=true&statuses={statuses}&customerId={selectedPartnerCustomerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_SIPTRUNK,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_CUSTOMER_SERVICE_PIN]: {
        URL_APPENDIX: `/customers/servicePin?servicePin={servicePin}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_CUSTOMER_SERVICE_PIN,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_PUT_CONTRACT_NOTES]: {
        URL_APPENDIX: `/customers/contracts/{contractId}/notes?customerId={selectedPartnerCustomerId}`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_PUT_CONTRACT_NOTES,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_CUSTOMER_MSTEAMS_CNAME]: {
        URL_APPENDIX: `/customers/teamsCname?contractId={contractId}&cname={cname}&customerId={selectedPartnerCustomerId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_CUSTOMER_MSTEAMS_CNAME,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_CLIS]: {
        URL_APPENDIX: `/customers/contracts/{contractId}/callNumbers?customerId={selectedPartnerCustomerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_CLIS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_PUT_PROFILE]: {
        URL_APPENDIX: `/customers/profile`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_PUT_PROFILE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_BILLS]: {
        URL_APPENDIX: `/customer/bill?language={language}&pdf=true`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_BILLS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_ITEMIZED_BILLS]: {
        URL_APPENDIX: `/customer/bill?language={language}&pdf=false`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_ITEMIZED_BILLS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_ITEMIZED_BILLS_FOR_TELEFONICA_CUSTOMERS]: {
        URL_APPENDIX: `/customer/bill/evn/telefonica?language={language}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_ITEMIZED_BILLS_FOR_TELEFONICA_CUSTOMERS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_BILL_FILE_ENV]: {
        URL_APPENDIX: `/customer/bill/{billId}/evn/csv`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_BILL_FILE_ENV,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_BILL_FILE_ENV_TELEFONICA]: {
        URL_APPENDIX: `/customer/bill/evn/telefonica/bill/{billId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_BILL_FILE_ENV_TELEFONICA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_BILL_FILE]: {
        URL_APPENDIX: `/customer/bill/{billId}/pdf`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_BILL_FILE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_LICENSES]: {
        URL_APPENDIX: `/customers/contracts/{contractId}/licenses?customerId={selectedPartnerCustomerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_LICENSES,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_LICENSES_BY_SERVICE_GROUP]: {
        URL_APPENDIX: `/customers/contracts/{contractId}/servicegroups/licenses?customerId={selectedPartnerCustomerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_LICENSES_BY_SERVICE_GROUP,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_LICENSES_PREPARE_ORDER]: {
        URL_APPENDIX: `/orderOption/prepareOrder?serviceGroup={serviceGroup}&option={option}&customerId={selectedPartnerCustomerId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_LICENSES_PREPARE_ORDER,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_EXTENSIONS]: {
        URL_APPENDIX: `/customers/contracts/{contractId}/extensions?customerId={selectedPartnerCustomerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_EXTENSIONS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_ADD_OPTIONS]: {
        URL_APPENDIX: `/customers/contracts/{contractId}/addOption?optionId={optionId}&quantity={quantity}&customerId={selectedPartnerCustomerId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_ADD_OPTIONS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_SEND_TERMINATION]: {
        URL_APPENDIX: `/customers/contracts/terminateContracts?customerId={selectedPartnerCustomerId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_SEND_TERMINATION,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_OPTIONS]: {
        URL_APPENDIX: `/products/{id}/options`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_OPTIONS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      // Basket
      [ACTION_CONST.API_BASKET_CREATE_ORDER_ENTRY ]: {
        URL_APPENDIX: `/baskets?expanded=true`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_BASKET_CREATE_ORDER_ENTRY,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_SET_LANGUAGE ]: {
        URL_APPENDIX: `/baskets/{basketId}/translate`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_BASKET_SET_LANGUAGE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_SEND_ORDER]: {
        URL_APPENDIX: `/customers/contracts/sendOrder?basketId={basketId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_SEND_ORDER,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_PUT_PRODUCT]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{productId}?expanded=true`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_BASKET_PUT_PRODUCT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_DELETE_PRODUCT]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{index}?expanded=true`,
        METHOD: 'delete',
        ACTION: ACTION_CONST.API_BASKET_DELETE_PRODUCT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_PUT_ADD_OPTION]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{uuid}/option/{optionId}`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_BASKET_PUT_ADD_OPTION,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA]: {
        URL_APPENDIX: `/baskets/entries/option/telcoData`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_ADD_TELCO_DATA]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{uuid}/telcoData`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_BASKET_ADD_TELCO_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA_ORDER]: {
        URL_APPENDIX: `/baskets/entries/option/telcoData`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA_ORDER,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_PUT_ACTIVATION_DATE]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{uuid}/startDtRequested?activationDate={activationDate}`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_BASKET_PUT_ACTIVATION_DATE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      // DUPLICATE
      [ACTION_CONST.API_PUT_OE_PRODUCT_OPTIONS]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{uuid}/option/{optionId}`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_PUT_OE_PRODUCT_OPTIONS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_CUSTOM_DATA]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{uuid}/option/{optionId}/customData`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_CUSTOM_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      // DUPLICATE
      [ACTION_CONST.API_BASKET_DELETE_OPTION]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{uuid}/option/{optionId}`,
        METHOD: 'delete',
        ACTION: ACTION_CONST.API_BASKET_DELETE_OPTION,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_DELETE_OPTION_BY_UUID]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{parentUuid}/optionUuid/{optionUuid}`,
        METHOD: 'delete',
        ACTION: ACTION_CONST.API_BASKET_DELETE_OPTION_BY_UUID,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      // DUPLICATE
      [ACTION_CONST.API_DELETE_OE_PRODUCT_OPTIONS]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{uuid}/option/{optionId}`,
        METHOD: 'delete',
        ACTION: ACTION_CONST.API_DELETE_OE_PRODUCT_OPTIONS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_DELETE_OPTION_INDEX]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/index/{entryIndex}/option/{optionIndex}`,
        METHOD: 'delete',
        ACTION: ACTION_CONST.API_BASKET_DELETE_OPTION_INDEX,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_PUT_DOCUMENT]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/documents?expanded=true`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_BASKET_PUT_DOCUMENT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_DELETE_DOCUMENT]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/documents`,
        METHOD: 'delete',
        ACTION: ACTION_CONST.API_BASKET_DELETE_DOCUMENT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      // DUPLICATE
      [ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{uuid}/optionGroups?expanded=true&language={language}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS_FOR_QUOTES]: {
        URL_APPENDIX: `/quotes/{basketId}/entries/{uuid}/optionGroups?expanded=true&language={language}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS_FOR_QUOTES,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS_FOR_SIP_TRUNK]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{uuid}/optionGroups?expanded=true&language={language}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS_FOR_SIP_TRUNK,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      // DUPLICATE
      [ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{uuid}/optionGroups?expanded=true&language={language}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_PREPARE_CHANGE_PRODUCT]: {
        URL_APPENDIX: `/changeProduct/prepareOrder?customerId={selectedPartnerCustomerId}&optionContractId={optionContractId}&serviceId={serviceId}&expanded=true`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_PREPARE_CHANGE_PRODUCT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_CHANGE_PRODUCT_SEND_ORDER]: {
        URL_APPENDIX: `/changeProduct/sendOrder?customerId={selectedPartnerCustomerId}&contractId={contractId}&basketId={basketId}&changeRequestDate={changeRequestDate}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_CHANGE_PRODUCT_SEND_ORDER,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      //migration order entry
      [ACTION_CONST.API_GET_OE_MIGRATION_CUSTOMER]: {
        URL_APPENDIX: `/orderEntries/migration/partner/{partnerVoNumber}/customer/{customerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_OE_MIGRATION_CUSTOMER,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      }, [ACTION_CONST.API_GET_OE_MIGRATION_CUSTOMER_PRODUCT]: {
        URL_APPENDIX: `/orderEntries/migration/partner/{partnerVoNumber}/customer/{customerId}/service`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_OE_MIGRATION_CUSTOMER_PRODUCT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      }, [ACTION_CONST.API_GET_OE_MIGRATION_CUSTOMER_SERVICE_DATA]: {
        URL_APPENDIX: `/orderEntries/migration/partner/{partnerVoNumber}/customer/{customerId}/service/{serviceId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_OE_MIGRATION_CUSTOMER_SERVICE_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_ADD_CHANNEL_TO_BASKET]: {
        URL_APPENDIX: `/orderEntries/prepareBasket/sipTrunkMigrationService?basketId={basketId}&entryUuid={entryUuid}&customerId={customerId}&partnerId={partnerId}&serviceId={serviceId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_ADD_CHANNEL_TO_BASKET,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_GET_MIGRATION_HINTS]: {
        URL_APPENDIX: `/orderEntries/migration/partner/{partnerVoNumber}/customer/{customerId}/service/{serviceId}/hints`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_GET_MIGRATION_HINTS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_ACCEPT_MIGRATION_HINTS]: {
        URL_APPENDIX: `/orderEntries/migration/prepareBasket/migrationHints?basketId={basketId}&entryUuid={entryUuid}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_ACCEPT_MIGRATION_HINTS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_ADD_CALLNUMBERS_TO_BASKET]: {
        URL_APPENDIX: `/orderEntries/prepareBasket/telephonyConnectionMigrationService?basketId={basketId}&entryUuid={entryUuid}&customerId={customerId}&partnerId={partnerId}&serviceId={serviceId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_ADD_CALLNUMBERS_TO_BASKET,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION_FOR_MIGRATION]: {
        URL_APPENDIX: `/baskets/{basketId}/entries/{uuid}/optionGroupsForMigration?expanded=true&language={language}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_OE_OPTIONS_OF_OPTION_FOR_MIGRATION,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      //documents
      [ACTION_CONST.API_DOCUMENTS_POST_DOCUMENT]: {
        URL_APPENDIX: `/documents?documentTypeId={documentTypeId}&convertPdfTo={convertPdfTo}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_DOCUMENTS_POST_DOCUMENT,
        NEEDS_TOKEN: true,
        TYPE: 'MEDIA',
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_DOCUMENTS_DELETE_DOCUMENT]: {
        URL_APPENDIX: `/documents/{documentId}`,
        METHOD: 'delete',
        ACTION: ACTION_CONST.API_DOCUMENTS_DELETE_DOCUMENT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_DOCUMENTS_DOWNLOAD_DOCUMENT]: {
        URL_APPENDIX: `/documents/{documentId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_DOCUMENTS_DOWNLOAD_DOCUMENT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      //orderCallNumber
      [ACTION_CONST.API_OCN_GET_CUSTOMER_LEGAL_ENTITIES]: {
        URL_APPENDIX: `/orderCallNumber/contracts/{contractId}/customerLegalEntities?customerId={selectedPartnerCustomerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_OCN_GET_CUSTOMER_LEGAL_ENTITIES,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_OCN_GET_PREPARE_ORDER]: {
        URL_APPENDIX: `/orderCallNumber/prepareOrder?telePhoneLineContractId={telePhoneLineContractId}&telePhoneLineServiceId={telePhoneLineServiceId}&customerId={selectedPartnerCustomerId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_OCN_GET_PREPARE_ORDER,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_OCN_PUT_BASKET_ADD_OPTION]: {
        URL_APPENDIX: `/orderCallNumber/basket/addOption?customerId={selectedPartnerCustomerId}`,
        METHOD: 'put',
        ACTION: ACTION_CONST.API_OCN_PUT_BASKET_ADD_OPTION,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_OCN_POST_COMPLETE]: {
        URL_APPENDIX: `/orderCallNumber/complete?basketId={basketId}&customerId={selectedPartnerCustomerId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_OCN_POST_COMPLETE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_OCN_DELETE_BASKET_REMOVE_OPTION]: {
        URL_APPENDIX: `/orderCallNumber/basket/removeOption?basketId={basketId}&theParentUuid={theParentUuid}&callNumberUuid={callNumberUuid}`,
        METHOD: 'delete',
        ACTION: ACTION_CONST.API_OCN_DELETE_BASKET_REMOVE_OPTION,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      //OrderSipTrunk
      [ACTION_CONST.API_OST_GET_PREPARE_CHANGE]: {
        URL_APPENDIX: `/orderSipTrunk/prepareChange?contractId={contractId}&customerId={selectedPartnerCustomerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_OST_GET_PREPARE_CHANGE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_OST_POST_SUBMIT_CHANGE]: {
        URL_APPENDIX: `/orderSipTrunk/submitChange?basketId={basketId}&customerId={selectedPartnerCustomerId}&changeRequestDate={changeRequestDate}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_OST_POST_SUBMIT_CHANGE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      //partner
      [ACTION_CONST.API_GET_PARTNER_CUSTOMERS]: {
        URL_APPENDIX: `/partner/customers`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_CUSTOMERS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_QUOTES]: {
        URL_APPENDIX: `/quotes`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_QUOTES,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_QUOTE]: {
        URL_APPENDIX: `/quotes/{quoteId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_QUOTE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_RELEASE_PARTNER_QUOTE]: {
        URL_APPENDIX: `/quotes/{quoteId}/release`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_RELEASE_PARTNER_QUOTE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_EDIT_PARTNER_QUOTE]: {
        URL_APPENDIX: `/quotes/{quoteId}/segments/{segmentId}/edit?basketId={basketId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_EDIT_PARTNER_QUOTE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_REQUIRED_DOCUMENTS]: {
        URL_APPENDIX: `/quotes/{quoteId}/documents`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_REQUIRED_DOCUMENTS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_PARTNER_QUOTE_DOCUMENTS]: {
        URL_APPENDIX: `/quotes/{quoteId}/documents`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_PARTNER_QUOTE_DOCUMENTS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_DELETE_PARTNER_QUOTE_DOCUMENTS]: {
        URL_APPENDIX: `/quotes/{quoteId}/documents`,
        METHOD: 'delete',
        ACTION: ACTION_CONST.API_DELETE_PARTNER_QUOTE_DOCUMENTS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_CUSTOMER_QUOTE]: {
        URL_APPENDIX: `/quotes/customers/{quoteId}?token={token}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_CUSTOMER_QUOTE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.AUTHORIZATION_CODE,
      },
      [ACTION_CONST.API_GET_CUSTOMER_QUOTE_DOCUMENT]: {
        URL_APPENDIX: `/quotes/customers/{quoteId}/document?token={token}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_CUSTOMER_QUOTE_DOCUMENT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.AUTHORIZATION_CODE,
      },
      [ACTION_CONST.API_POST_CUSTOMER_QUOTE_ACCEPT]: {
        URL_APPENDIX: `/quotes/customers/{quoteId}/accept?token={token}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_CUSTOMER_QUOTE_ACCEPT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.AUTHORIZATION_CODE,
      },
      [ACTION_CONST.API_POST_CUSTOMER_QUOTE_REJECT]: {
        URL_APPENDIX: `/quotes/customers/{quoteId}/reject?token={token}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_CUSTOMER_QUOTE_REJECT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.AUTHORIZATION_CODE,
      },
      [ACTION_CONST.API_POST_QUOTE_CHARGE]: {
        URL_APPENDIX: `/quotes/{quoteId}/charge/{chargeId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_QUOTE_CHARGE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_QUOTE_NAME]: {
        URL_APPENDIX: `/quotes/{quoteId}/name`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_QUOTE_NAME,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_REVISE_QUOTE]: {
        URL_APPENDIX: `/quotes/{quoteId}/revise`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_REVISE_QUOTE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_CANCEL_QUOTE]: {
        URL_APPENDIX: `/quotes/{quoteId}/cancel`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_CANCEL_QUOTE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_QUOTE_START_ORDER_PROCESS]: {
        URL_APPENDIX: `/quotes/{quoteId}/orderProcess`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_QUOTE_START_ORDER_PROCESS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_QUOTE_ACCOUNT]: {
        URL_APPENDIX: `/quotes/{quoteId}/accounts`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_QUOTE_ACCOUNT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_PREPARE_EDIT_QUOTE]: {
        URL_APPENDIX: `/quotes/{quoteId}/prepare`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_PREPARE_EDIT_QUOTE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_CUSTOMERS_DATA]: {
        URL_APPENDIX: `/partner/customer/{customerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_CUSTOMERS_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_CUSTOMERS_EXTENSIONS_DATA]: {
        URL_APPENDIX: `/partner/customer/{customerId}/extensions`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_CUSTOMERS_EXTENSIONS_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_CUSTOMERS_MENU_ITEMS]: {
        URL_APPENDIX: `/customers/contracts/{contractId}/menuItems?customerId={selectedPartnerCustomerId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_CUSTOMERS_MENU_ITEMS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_CUSTOMERS_ADDRESSES_DATA]: {
        URL_APPENDIX: `/partner/customer/{customerId}/addresses`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_CUSTOMERS_ADDRESSES_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_CUSTOMERS_CLI_DATA]: {
        URL_APPENDIX: `/partner/customer/{customerId}/callNumbers`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_CUSTOMERS_CLI_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_CUSTOMERS_CHARGE_SUM]: {
        URL_APPENDIX: `/partner/customer/{customerId}/contracts/totalPrice`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_CUSTOMERS_CHARGE_SUM,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_CUSTOMERS_ACCOUNTS]: {
        URL_APPENDIX: `/partner/customer/{customerId}/accounts`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_CUSTOMERS_ACCOUNTS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_CUSTOMERS_COMMUNICATIONS]: {
        URL_APPENDIX: `/partner/customer/{customerId}/communications`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_CUSTOMERS_COMMUNICATIONS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_CUSTOMERS_WORKFLOWS]: {
        URL_APPENDIX: `/partner/customer/{customerId}/workflowsInfo?workflowId={workflowId}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_CUSTOMERS_WORKFLOWS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_COMMISSION_DATA]: {
        URL_APPENDIX: `/partner/bill/commissionBills?language={language}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_COMMISSION_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_COMMISSION_BILLS_CSV]: {
        URL_APPENDIX: `/partner/bill/commissionBills/{commissionBillId}/csv`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_COMMISSION_BILLS_CSV,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_COMMISSION_BILLS_PDF]: {
        URL_APPENDIX: `/partner/bill/commissionBills/{commissionBillId}/pdf`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_COMMISSION_BILLS_PDF,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_WHOLESALER_SDR_CSV]: {
        URL_APPENDIX: `/wholesale/bill/sdr/{sdrDocumentId}/csv`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_WHOLESALER_SDR_CSV,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_WHOLESALER_CDR_DATA]: {
        URL_APPENDIX: `/wholesale/bill/cdrs?language={language}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_WHOLESALER_CDR_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_WHOLESALER_CDR_CSV]: {
        URL_APPENDIX: `/wholesale/bill/cdr/{cdrDocumentId}/csv`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_WHOLESALER_CDR_CSV,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_WHOLESALER_SDR_DATA]: {
        URL_APPENDIX: `/wholesale/bill/sdrs?language={language}`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_WHOLESALER_SDR_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PARTNER_CUSTOM_CONTACT_PERSON]: {
        URL_APPENDIX: `/partner/customContactPerson`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PARTNER_CUSTOM_CONTACT_PERSON,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_PARTNER_CUSTOM_CONTACT_PERSON]: {
        URL_APPENDIX: `/partner/customContactPerson`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_PARTNER_CUSTOM_CONTACT_PERSON,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      //lookups some data
      [ACTION_CONST.API_GET_LANGUAGES]: {
        URL_APPENDIX: `/lookups/language?expanded=true&onlyActive=true`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_LANGUAGES,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_SALUTATIONS]: {
        URL_APPENDIX: `/lookups/salutation?expanded=true&onlyActive=true`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_SALUTATIONS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_LEGAL_FORMS]: {
        URL_APPENDIX: `/lookups/legalForm?expanded=true&onlyActive=true`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_LEGAL_FORMS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_COUNTRY]: {
        URL_APPENDIX: `/lookups/country?expanded=true&onlyActive=true`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_COUNTRY,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_BILL_MEDIA]: {
        URL_APPENDIX: `/lookups/billMedia?expanded=true&onlyActive=true`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_BILL_MEDIA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_PAYMENT_MODE]: {
        URL_APPENDIX: `/lookups/paymentMode?expanded=true&onlyActive=true`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_PAYMENT_MODE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_CARRIER]: {
        URL_APPENDIX: `/lookups/carrier?expanded=true&onlyActive=true`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_CARRIER,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_BASKET_PREPARE_ORDER_ENTRY ]: {
        URL_APPENDIX: `/orderEntries/prepareBasket?basketId={basketId}&addressCountryId={addressCountryId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_BASKET_CREATE_ORDER_ENTRY,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_VALIDATE_CUSTOMER_DATA]: {
        URL_APPENDIX: `/orderEntries/validation?isQuote={isQuote}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_VALIDATE_CUSTOMER_DATA,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_VALIDATE_AREA_CODE]: {
        URL_APPENDIX: `/orderEntries/validateAreaCode?addressZip={addressZip}&areaCode={areaCode}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_VALIDATE_AREA_CODE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_VALIDATE_LOCAL_AREA_CODE]: {
        URL_APPENDIX: `/orderEntries/validateLocalAreaCode?countryId={countryId}&onkz={onkz}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_VALIDATE_AREA_CODE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_CREATE_CUSTOMER]: {
        URL_APPENDIX: `/orderEntries/createCustomer`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_CREATE_CUSTOMER,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_CREATE_CUSTOMER_QUOTE]: {
        URL_APPENDIX: `/orderEntries/createCustomerQuote`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_CREATE_CUSTOMER_QUOTE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_MIGRATE_CUSTOMER]: {
        URL_APPENDIX: `/orderEntries/migrateCustomer`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_MIGRATE_CUSTOMER,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_CREATE_CUSTOMER_CONTRACT]: {
        URL_APPENDIX: `/orderEntries/createCustomerContract`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_CREATE_CUSTOMER_CONTRACT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_MIGRATE_CUSTOMER_CONTRACT]: {
        URL_APPENDIX: `/orderEntries/migrateCustomerContract`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_MIGRATE_CUSTOMER_CONTRACT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_OE_PRODUCTS]: {
        URL_APPENDIX: `/products?expanded=true&client={legalEntityId}&salesPartner={partnerId}&distributionChannel=1`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_OE_PRODUCTS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_QUOTE_PRODUCTS]: {
        URL_APPENDIX: `/quotes/products?expanded=true&client={legalEntityId}&salesPartner={partnerId}&distributionChannel=1`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_QUOTE_PRODUCTS,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_PORTAL_FEATURE]: {
        URL_APPENDIX: `/features`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_PORTAL_FEATURE,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      // fraud
      [ACTION_CONST.API_GET_FRAUDLOCK_OF_CONTRACT]: {
        URL_APPENDIX: `/fraud/locked`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_FRAUDLOCK_OF_CONTRACT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_FRAUD_UNLOCK_OF_CONTRACT]: {
        URL_APPENDIX: `/fraud/unlocked?lockedContractId={lockedContractId}`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_FRAUD_UNLOCK_OF_CONTRACT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_GET_FRAUD_LIMIT]: {
        URL_APPENDIX: `/fraud/limits`,
        METHOD: 'get',
        ACTION: ACTION_CONST.API_GET_FRAUD_LIMIT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
      [ACTION_CONST.API_POST_FRAUD_LIMIT]: {
        URL_APPENDIX: `/fraud/limits`,
        METHOD: 'post',
        ACTION: ACTION_CONST.API_POST_FRAUD_LIMIT,
        NEEDS_TOKEN: true,
        GRANT_TYPE: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
      },
    },
  },

  PERSIST_STORE: {
    PERSIST_FOR_SESSION: true,
    KEY: 'nfon',
    WHITELIST: ['apiCallStatus', 'basket', 'config', 'customer', 'extensions', 'lang', 'products', 'partners', 'partnerCustomers', 'user', 'stepper', 'lookups'],
    BLACKLIST: [''],
  },
};

export default MainConfigConstants;
