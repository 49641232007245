import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { CpxTitle } from "../../../../core/components/title/title.component";
import "./quoteDetail.scss";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import cs from "classnames";
import { useSelectError, useStep1Data } from "../../../redux/store";
import { Quote, QuoteDocument } from "compax-api";
import { useTheme } from "../../../../core/utility/themeContext";
import { CpxInputWithLabel } from "../../../../core/components/inputWithLabel.component";
import { CpxIcon } from "../../../../core/components/icon.component";
import { ICONS, ID_STATUS_QUOTES, ORDER_TYPE } from "../../../constants/configs/config.constants";
import { CpxButton } from "../../../../core/components/button.component";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { addDocument, answerQuoteBody, releaseQuoteBody, saveOverridePrice } from "../../../utils/helper/requestBodyHelper";
import { Alert } from "../../../components/common/Alert/Alert.component";
import { clearError } from "../../../redux/actions/error.action";
import { translateAddressToPortalAddress, translateCustomerToCustomerPortal } from "../../../../core/utils";
import { SendQuoteModal } from "./sendQuoteModal.component";
import { NfoQuoteOverview } from "./quoteOverview.component";
import { deleteStepData, saveCurrentOrderType, saveCurrentStep, saveStep1Data } from "../../../redux/actions/step.action";
import { routes } from "../../../constants/routes/routes";
import { useHistory, useParams } from "react-router-dom";
import { CpxPaginatedTable } from "../../../../core/components/paginatedTable.component";
import { NfoEmptyState } from "../../../../core/emptyState.component";
import { CpxDropdownWithLabel } from "../../../../core/components/dropdown.component";
import { NfoQuoteAccountModal } from "./quoteAccountModal.component";
import { NfoRemoveConfigurationConfirmationModal } from "../../../components/partner/newCustomer/confirmModal/confirmationModal.component";
import { useToast } from "../../../components/common/header/toast/toast.component";
import { ToastTypes } from "../../../constants/types/types.constants";
import { NfoCollapsiblePanel } from "./collapsiblePanel.component";
import { NfoQuoteItemsTable } from "./quoteItemsTable.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

interface QuoteParameter {
  quoteId: string;
}

export const NfoQuoteDetailPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.createQuote);
  const internalClassName = "quoteDetail";
  const theme = useTheme();
  const [selectedQuote, setSelectedQuote] = useState<Quote>();
  const ErrorsOfBE = useSelectError();
  const history = useHistory();
  const step1Data = useStep1Data();
  const hiddenFileInput = React.useRef([] as Array<any>);
  const [index, setIndex] = useState(0);
  const toast = useToast();
  const params = useParams<QuoteParameter>();

  useEffect(() => {
    hiddenFileInput.current = hiddenFileInput.current.slice(0, selectedQuote?.requiredDocuments?.length)
  }, [selectedQuote]);

  const getAllCharges = (item: any) => {
    const optionCharges = item.options.flatMap((option: any) => getAllCharges(option)) || []
    return [...item.charges, ...optionCharges]
  }

  const [showSendModal, setShowSendModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)
  const [editName, setEditName] = useState(false)
  const [name, setName] = useState(selectedQuote?.name || '')

  const getEditCharges = (quote: Quote | undefined) => {
    return new Map<number, boolean>(quote?.segments?.flatMap(segment => segment.items?.flatMap(item => getAllCharges(item))).map(charge => [Number(charge.id), false]));
  }

  const getEditChargesValue = (quote: Quote | undefined) => {
    return new Map<number, number>(quote?.segments?.flatMap(segment => segment.items?.flatMap(item => getAllCharges(item))).map(charge => [Number(charge.id), charge.amountNetOverride !== undefined ? charge.amountNetOverride : charge?.amountNet]))
  }

  const [editCharges, setEditCharges] =
    useState(getEditCharges(selectedQuote));
  const [editedChargesValue, setEditedChargesValue] =
    useState(getEditChargesValue(selectedQuote));

  const updateChargeEdit = (key: number, value: boolean) => {
    setEditCharges((prevMap) => {
      const tmpChargeNotes = new Map(prevMap);
      tmpChargeNotes.set(key, value);
      return tmpChargeNotes;
    })
  }

  const updateChargesValue = (key: number, value: number) => {
    setEditedChargesValue((prevMap) => {
      const tmpChargesValue = new Map(prevMap);
      tmpChargesValue.set(key, value);
      return tmpChargesValue;
    })
  }

  const reloadSelectedQuote = (postAction?: any) => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNER_QUOTE, { quoteId: selectedQuote?.id })).then((res) => {
      setSelectedQuote(res)
      if (postAction) {
        postAction();
      }
    })
  }

  useEffect(() => {
    // reloadSelectedQuote();
  }, []);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNER_QUOTE, { quoteId: params.quoteId })).then((res) => {
      setSelectedQuote(res)
      setEditCharges(getEditCharges(res))
      setEditedChargesValue(getEditChargesValue(res))
    })
  }, [params.quoteId]);


  useEffect(() => {
    setName(selectedQuote?.name || '')
  }, [selectedQuote]);

  const saveValue = (key: number | undefined) => {
    dispatch(clearError())
    if (key !== undefined) {
      dispatch(apiCallAction(ACTION_CONST.API_POST_QUOTE_CHARGE, { ...saveOverridePrice(editedChargesValue.get(Number(key))), quoteId: selectedQuote?.id, chargeId: key }))
        .then(() => {
          dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNER_QUOTE, { quoteId: selectedQuote?.id })).then((res) => {
            setSelectedQuote(res)
            updateChargeEdit(Number(key), false);
          })
        }).catch((e) => {

      })
    }
  }

  const reviseQuote = () => {
    dispatch(clearError())
    dispatch(apiCallAction(ACTION_CONST.API_POST_REVISE_QUOTE, { quoteId: selectedQuote?.id }))
      .then((res) => {
        history.push(routes.partnerQuoteDetail.path.replace(":quoteId", `${res}`));
      }).catch((e) => {
    })
  }

  const selectQuote = (quote: Quote) => {
    history.push(routes.partnerQuoteDetail.path.replace(":quoteId", `${quote.id}`));
  }

  const editQuote = () => {
    dispatch(deleteStepData());
    dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNER_CUSTOMERS_DATA, { customerId: selectedQuote?.customer?.id })).then((result) => {
      dispatch(saveStep1Data({ ...step1Data, customer: translateCustomerToCustomerPortal(result), mainAddress: translateAddressToPortalAddress(result?.mainAddress) }));
      dispatch(saveCurrentStep(4));
      dispatch(saveCurrentOrderType(ORDER_TYPE.ORDER_EDIT_QUOTE));
      dispatch(apiCallAction(ACTION_CONST.API_POST_PREPARE_EDIT_QUOTE, { quoteId: selectedQuote?.id })).then(() => {
        history.push(routes.partnerCustomersEditQuote.path.replace(":quoteId", `${selectedQuote?.id}`).replace(":segmentId", `${selectedQuote?.segments && selectedQuote?.segments[0].id}`));
      })
    })
  }

  const cancelQuote = () => {
    dispatch(clearError())
    dispatch(apiCallAction(ACTION_CONST.API_POST_CANCEL_QUOTE, { quoteId: selectedQuote?.id }))
      .then((res) => {
        reloadSelectedQuote(() => setShowCancelModal(false));
      }).catch((e) => {
    })
  }

  const handleChangeName = (e: any) => {
    setName((oldValue) => e.target.value)
  }

  const toggleEditName = () => {
    if (editName) {
      setName(selectedQuote?.name || '')
    }
    setEditName(x => !x)
  }

  const saveName = () => {
    dispatch(clearError());
    dispatch(apiCallAction(ACTION_CONST.API_POST_QUOTE_NAME, { ...answerQuoteBody(name), quoteId: selectedQuote?.id })).then((res) => {
      setSelectedQuote(res)
      setEditName(false)
    })
  }

  const getNameInputSuffix = () => {
    return (
      <div className={"buttons"}>
        {editName && <CpxButton className={'iconOnly'} type={'button'} onClick={(event) => saveName()}><CpxIcon icon={ICONS.CHECK}/></CpxButton>}
        {editName && <CpxButton className={cs('iconOnly', `${internalClassName}-cross-btn ${internalClassName}-cross-btn-le--${theme}`)} type={'button'} onClick={() => toggleEditName()}><CpxIcon icon={ICONS.CROSS}/></CpxButton>}
        {!editName && <CpxButton className={'iconOnly'} type={'button'} onClick={(event) => toggleEditName()}><CpxIcon icon={ICONS.EDIT}/></CpxButton>}
      </div>

    )
  }

  const handleDownloadFile = (e: any, id: any) => {
    selectedQuote?.quoteDocument?.id &&
    dispatch(apiCallAction(ACTION_CONST.API_DOCUMENTS_DOWNLOAD_DOCUMENT, { documentId: id })).then((res) => {
      const link = document.createElement("a");
      link.href = `data:${res.mimeType};base64,${res.file}`;
      link.download = res.documentName;
      link.click();
      link.remove();

    });
  }

  const handleDownloadQuoteFile = (e: any) => {
    selectedQuote?.quoteDocument?.id && handleDownloadFile(e, selectedQuote?.quoteDocument?.id)
  }

  const handleDropDownChange = (e: any, quoteDocument: QuoteDocument) => {
    quoteDocument.documentType = quoteDocument.documentTypes && quoteDocument.documentTypes[e.target.value];
  }


  const handleUploadFile = (event: any) => {
    const document = selectedQuote?.requiredDocuments && selectedQuote?.requiredDocuments[index];
    if (!document) {
      return;
    }

    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('file', fileUploaded);

    //api call to upload file
    dispatch(apiCallAction(ACTION_CONST.API_DOCUMENTS_POST_DOCUMENT, { documentTypeId: document.documentType ? document.documentType.id : document.documentTypes && document.documentTypes[0].id, file: fileUploaded, convertPdfTo: "" }, true)).then(
      (res: any) => {
        //api call to add to basket
        dispatch(apiCallAction(ACTION_CONST.API_POST_PARTNER_QUOTE_DOCUMENTS,
          {
            ...addDocument(document.uuids, res, document.documentType ? document.documentType : document.documentTypes && document.documentTypes[0], fileUploaded.name),
            quoteId: selectedQuote?.id
          }
        )).then((res) => {
          reloadSelectedQuote();
        });

      }
    );
  };

  const handleDeleteDocument = (event: any, document: QuoteDocument) => {

    if (document.id) {
      //delete api call from server
      dispatch(apiCallAction(ACTION_CONST.API_DOCUMENTS_DELETE_DOCUMENT, { documentId: document.id }));
      //delete api call from quote basket
      dispatch(apiCallAction(ACTION_CONST.API_DELETE_PARTNER_QUOTE_DOCUMENTS,
        {
          ...addDocument(document.uuids, document.id, document.documentType ? document.documentType : document.documentTypes && document.documentTypes[0], document?.documentName || ''),
          quoteId: selectedQuote?.id
        }
      )).then((res) => {
        reloadSelectedQuote();
      });
    }
  }

  const handleClickFakeButton = (index: number) => {
    setIndex(index)
    hiddenFileInput?.current[index]?.click();
  };

  const documentsTableRowData = (document: QuoteDocument, index: number) => ({
    id: "" + index,
    cellData: [
      <div>
        <p className={cs(`${internalClassName}-label`, `${internalClassName}-label-le--${theme}`)}>{document.labelName}</p>
      </div>,
      <>
        {document.documentTypes && document.documentTypes.length > 1 && <CpxDropdownWithLabel
          id={`documents[${index}].documentType`}
          options={document.documentTypes.map((type, index) => {
            return { id: index, name: type.description || '', data: type }
          })}
          onChange={(e: any) => handleDropDownChange(e, document)}/>}
      </>,
      (document?.id) ?
        <a target="_blank" onClick={e => handleDownloadFile(e, document.id)} className={cs("tableFirstChild ", `tableFirstChild-font-le--${theme}`)}>{document.documentName} </a> :
        <><input onChange={(e) => handleUploadFile(e)} type='file' ref={el => hiddenFileInput.current[index] = el} accept="application/pdf" hidden/><CpxButton onClick={() => handleClickFakeButton(index)}>{translations.documentsUploadFile()}</CpxButton></>,
      <div className={cs(`${internalClassName}-actions`, `${internalClassName}-actions-le--${theme}`)}>
        {selectedQuote?.status?.id === ID_STATUS_QUOTES.CONTRACT_DOCUMENT_SENT && <CpxButton className={"iconOnly"} type={'button'} onClick={e => handleDeleteDocument(e, document)}><CpxIcon icon={ICONS.DELETE}/></CpxButton>}
      </div>
    ],
  });

  const startOrderProcess = () => {
    dispatch(clearError())
    dispatch(apiCallAction(ACTION_CONST.API_POST_QUOTE_START_ORDER_PROCESS, { quoteId: selectedQuote?.id }))
      .then((res) => {
        reloadSelectedQuote();
        toast.showToast(ToastTypes.SUCCESS, translations.startedOrderProcess(), translations.startedOrderProcessSubTitle(), 5000);
      }).catch((e) => {
    })
  }

  const releaseQuote = (values: any) => {
    dispatch(apiCallAction(ACTION_CONST.API_POST_RELEASE_PARTNER_QUOTE, {...releaseQuoteBody(values.personalizedMessage, values.customDocumentName), quoteId: selectedQuote?.id})).then(((res) => {
      reloadSelectedQuote(() => {
        toast.showToast(ToastTypes.SUCCESS, translations.toastTitle(), translations.toastSubTitle(), 5000)
        setShowSendModal(false);
      })
    })).catch((res) => {
    })
  }

  return (
    <>
      {showSendModal && selectedQuote && <SendQuoteModal setShowModal={setShowSendModal} releaseQuote={releaseQuote}/>}
      {showCancelModal && <NfoRemoveConfigurationConfirmationModal onConfirm={cancelQuote} onCancel={() => setShowCancelModal(false)} confirmMessage={translations.confirmCancelQuote()}/>}
      {showAccountModal && <NfoQuoteAccountModal setShowModal={setShowAccountModal} quote={selectedQuote} reloadQuote={() => reloadSelectedQuote(() => {
        document.getElementById("account-panel")?.click();
        setShowAccountModal(false);
        toast.showToast(ToastTypes.SUCCESS, translations.addedAccountData(), "", 5000)
      })}/>}
      <div className={internalClassName}>
        <div className={cs('quotesTitle')}>
          <CpxTitle
            title={translations.quoteDetailTitle()}
            internalClassName={internalClassName}
          />
          <div className={"edit-buttons"}>
            <CpxButton onClick={() => reviseQuote()} className={"buttonIcons"}>
              <CpxIcon icon={ICONS.ADD}/>
              {translations.copyQuote()}
            </CpxButton>
            {selectedQuote?.status?.id === ID_STATUS_QUOTES.NEW && <CpxButton className={"buttonIcons"} onClick={() => editQuote()}><CpxIcon icon={ICONS.EDIT}/>{translations.editQuote()}</CpxButton>}
            {selectedQuote?.status?.id !== undefined
              && ![ID_STATUS_QUOTES.ORDERED, ID_STATUS_QUOTES.CANCELED, ID_STATUS_QUOTES.REFUSED_BY_CUSTOMER, ID_STATUS_QUOTES.START_ORDER_PROCESS].includes(selectedQuote?.status?.id)
              && <CpxButton className={"buttonIcons"} onClick={() => setShowCancelModal(true)} buttonType={"error"}><CpxIcon icon={ICONS.DELETE}/>{translations.cancelQuote()}</CpxButton>}
          </div>
        </div>
        {ErrorsOfBE?.errorData && ErrorsOfBE?.errorData?.length > 0 && <Alert errors={ErrorsOfBE?.errorData}/>}
        <div className={cs('quotesName')}>
          <CpxInputWithLabel value={name} disabled={!editName} onChange={handleChangeName} suffix={getNameInputSuffix()}/>
        </div>
        <NfoQuoteOverview quote={selectedQuote} selectQuote={selectQuote}/>

        {selectedQuote?.customerComment &&
          <>
            <h5 className={"message-title"}>{translations.commentFromCustomer()}</h5>
            <div className={"message"}>{selectedQuote?.customerComment}</div>
          </>
        }
        {selectedQuote?.predecessor && !selectedQuote?.customerComment && selectedQuote?.predecessor.customerComment &&
          <>
            <h5 className={"message-title"}>{translations.customerCommentFromPrevQuote(selectedQuote?.predecessor?.quoteIdVersion || '')}</h5>
            <div className={"message"}>{selectedQuote?.predecessor.customerComment}</div>
          </>
        }
        {selectedQuote?.quoteDocument &&
          <>
            <h5 className={"message-title"}>{translations.quoteDocument()}</h5>
            <div className={cs(`${internalClassName}-downloadDocument`, `${internalClassName}-downloadDocument-le--${theme}`)}><a target="_blank" onClick={e => handleDownloadQuoteFile(e)}
                                                                                                                                  className={cs("tableFirstChild", `tableFirstChild-font-le--${theme}`)}>{selectedQuote.quoteDocument.description}</a></div>
          </>
        }

        {selectedQuote?.segments && selectedQuote?.segments.filter(segment => segment.items && segment.items.length > 0).map(segment =>
          (
            <NfoQuoteItemsTable
              segment={segment}
              saveValue={saveValue}
              editedChargesValue={editedChargesValue}
              editCharges={editCharges}
              chargesEditable={selectedQuote?.status?.id === ID_STATUS_QUOTES.NEW}
              updateChargeEdit={updateChargeEdit}
              updateChargesValue={updateChargesValue}
            />
          )
        )}


        <div className={"send-quote"}>
          {selectedQuote?.status?.id === ID_STATUS_QUOTES.NEW && <CpxButton onClick={() => setShowSendModal(true)}>{translations.sendQuote()}</CpxButton>}
        </div>

        {selectedQuote?.status?.id !== undefined && [ID_STATUS_QUOTES.ACCEPTED_BY_CUSTOMER, ID_STATUS_QUOTES.CONTRACT_DOCUMENT_SENT, ID_STATUS_QUOTES.START_ORDER_PROCESS, ID_STATUS_QUOTES.ORDERED].includes(selectedQuote?.status?.id) &&
          <NfoCollapsiblePanel
            title={translations.accountData()}
            initialState={selectedQuote.accountCompletionRequired}
            icon={selectedQuote.accountCompletionRequired ? ICONS.STATUS.CANCELLED : ICONS.STATUS.ACTIVE}
          >
            <div>
              {selectedQuote?.status?.id === ID_STATUS_QUOTES.ACCEPTED_BY_CUSTOMER && selectedQuote.accountCompletionRequired &&
                <div className={"account-data"}><CpxButton onClick={() => setShowAccountModal(true)}>{translations.addAccountData()}</CpxButton></div>}
              {!selectedQuote.accountCompletionRequired && selectedQuote.account &&
                <div className={"account-data"}>
                  <p>{selectedQuote.account.paymentMean?.bankAccount?.accountHolder}</p>
                  <p>{selectedQuote.account.paymentMean?.bankAccount?.iban}</p>
                  <p>{selectedQuote.account.paymentMean?.bankAccount?.bic}</p>
                </div>
              }
            </div>
          </NfoCollapsiblePanel>
        }
        {selectedQuote?.status?.id !== undefined && [ID_STATUS_QUOTES.CONTRACT_DOCUMENT_SENT, ID_STATUS_QUOTES.START_ORDER_PROCESS, ID_STATUS_QUOTES.ORDERED].includes(selectedQuote?.status?.id) &&
          <>
            <NfoCollapsiblePanel
              title={translations.documents()}
              initialState={selectedQuote?.requiredDocuments && selectedQuote?.requiredDocuments.some(doc => !doc.id)}
              icon={selectedQuote?.requiredDocuments && selectedQuote?.requiredDocuments.some(doc => !doc.id) ? ICONS.STATUS.CANCELLED : ICONS.STATUS.ACTIVE}
            >
              {selectedQuote && selectedQuote?.requiredDocuments &&
                <div className={"quote-documents-table"}>
                  <NfoEmptyState
                    data={selectedQuote?.requiredDocuments}
                    pageTitle={translations.documents()}
                  >
                    <CpxPaginatedTable
                      id={internalClassName}
                      tableHeader={[
                        translations.documents(),
                        "",
                        translations.file(),
                        "",
                      ]}
                      tableData={selectedQuote?.requiredDocuments?.map(documentsTableRowData)}
                    />
                  </NfoEmptyState>
                </div>
              }
            </NfoCollapsiblePanel>
            <div className={"send-quote"}>
              {selectedQuote?.status?.id === ID_STATUS_QUOTES.CONTRACT_DOCUMENT_SENT && <CpxButton disabled={selectedQuote.requiredDocuments?.some(doc => !doc.id)} onClick={() => startOrderProcess()}>{translations.startOrderProcess()}</CpxButton>}
            </div>
          </>
        }

      </div>
    </>
  );
};
