import React from 'react';
import { useDispatch } from 'react-redux';
import './quoteOverview.scss';
import { Quote } from "compax-api";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../core/utility/themeContext";
import cs from "classnames";
import { NfoStatus } from "../../../../core/components/status.component";
import { ID_DATA_ENTITY } from "../../../constants/configs/config.constants";
import { formatDate } from "../../../../core/utils";
import { useSelectLang } from "../../../redux/store";


type Props = {
  quote?: Quote;
  selectQuote?: any;
};

export const NfoQuoteOverview = ({ quote, selectQuote }: Props) => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.createQuote);
  const theme = useTheme();
  const internalClassName = 'overview';
  const language = useSelectLang().selected;

  return (
    <>
      <article
        className={cs(internalClassName, `${internalClassName}-le--${theme}`)}
      >
        <div className={'column'}>
          <h5>ID</h5>
          <p>
            {quote?.id}
          </p>
        </div>
        <div className={'column'}>
          <h5>{translations.quoteNumber()}</h5>
          <p>
            {quote?.quoteIdVersion}
          </p>
        </div>
        <div className={'column'}>
          <h5>{translations.expiryDate()}</h5>
          <p>
            {quote?.expiryDate ? formatDate(new Date(quote.expiryDate as Date), language) : "-"}
          </p>
        </div>
        <div className={'column'}>
          <h5>{translations.status()}</h5>
          <NfoStatus
            statusClassName={cs(`content-le`, `content-le--${theme}`)}
            entity={{ id: ID_DATA_ENTITY.QUOTE }}
            status={{ id: quote?.status?.id !== undefined ? quote?.status.id : 0 }}
          />
        </div>
        {selectQuote && quote && quote?.predecessor && <div className={'column'}>
          <h5>{translations.predecessor()}</h5>
          <p>
            <a
              className={cs(
                'partnerCustomerButton',
                `partnerCustomerButton-font-le--${theme}`
              )}
              onClick={e => selectQuote(quote?.predecessor)}
            >
              {quote?.predecessor.quoteIdVersion}
            </a>
          </p>
        </div>}
        {selectQuote && quote && quote?.successors && quote?.successors.length > 0 && <div className={'column'}>
          <h5>{translations.successor()}</h5>
          {quote?.successors.map(successor => (<p>
            <a
              className={cs(
                'partnerCustomerButton',
                `partnerCustomerButton-font-le--${theme}`
              )}
              onClick={e => selectQuote(successor)}
            >
              {successor.quoteIdVersion}
            </a>
          </p>))}

        </div>}
      </article>
    </>
  );
};
