import axios from 'axios';
import Config from '../../constants/configs/main.config.constants';
import MainConfigConstants from '../../constants/configs/main.config.constants';
import Cookie from 'cookies-js';
import { ACTION_CONST } from '../../constants/action.constants';
import OAUTH2_GRANT_TYPES from '../../constants/main.oauth2-grant-types';
import { hideLoaderAction, showLoaderAction } from './loader.action';
import { LOCALSTORAGE_ACCESSTOKEN, LOCALSTORAGE_CLIENTTOKEN, LOCALSTORAGE_REFRESHTOKEN, SELECTED_PARTNER_CUSTOMER_ID, } from '../../constants/configs/config.constants';
import { routes } from "../../constants/routes/routes";

let requestQueue = [];
let accessTokenQueue = [];
let requestFailedQueue = [];
let accessTokenQueueTimeoutId = 0;
let requestQueueTimeoutId = 0;
let requestsWithLoaderCounter = 0;
let accessToken;
const accessTokenClientName = LOCALSTORAGE_CLIENTTOKEN;
const accessTokenPasswordName = LOCALSTORAGE_ACCESSTOKEN;
const accessTokenRefreshName = LOCALSTORAGE_REFRESHTOKEN;
const httpStatusCodeUnauthorized = 401;
const httpStatusCodeNotFound = 404;
const httpStatusCodeSuccessArea = [200, 299];
const httpStatusCodeErrorArea = [400, 599];
const retryRequestDelay = 500;

const startCallAction = () => dispatch => {
  dispatch({
    type: ACTION_CONST.API_CALL_START,
  });
};

const cacheRequestAction = () => dispatch => {
  dispatch({
    // type: ACTION_CONST.CACHE_REQUEST,
    // requestId,
    // timestamp: new Date.now()
  });
};

const doneCallAction = (/*requestId*/) => dispatch => {
  dispatch({
    type: ACTION_CONST.API_CALL_DONE,
    //requestId,
    //timestamp: Date.now()
  });
};

const errorCallAction = data => dispatch => {
  dispatch({
    type: ACTION_CONST.API_CALL_ERROR,
    data: data,
  });
};

const loginCallAction = () => dispatch => {
  dispatch({
    type: ACTION_CONST.API_LOGIN_CALL,
  });
};

const authenticateCallAction = () => dispatch => {
  dispatch({
    type: ACTION_CONST.API_AUTHENTICATE_CALL,
  });
};

export const logoutCallAction = () => dispatch => {
  dispatch({
    type: ACTION_CONST.LOGOUT_CALL,
  });
};


const getAccessToken = (type, loginData) => {
  return new Promise(async (resolve, reject) => {
    let baseUrl = process.env.REACT_APP_AUTH_URL;
    let data;
    let cookieName = '';
    let refreshToken = localStorage.getItem(accessTokenRefreshName);

    if (refreshToken && refreshToken !== 'undefined') {
      cookieName = accessTokenPasswordName;
      data = {
        grant_type: OAUTH2_GRANT_TYPES.REFRESH_TOKEN,
        scope: 'all',
        refresh_token: refreshToken,
      };
    } else {
      if (type === OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS && loginData.user) {
        cookieName = accessTokenPasswordName;
        data = {
          grant_type: OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS,
          scope: 'all',
        };
      } else {
        cookieName = accessTokenClientName;
        data = {
          grant_type: OAUTH2_GRANT_TYPES.CLIENT_CREDENTIALS,
          scope: 'all',
        };
      }
    }

    if (Config.DEBUG) baseUrl = Config.PROXY + baseUrl;

    accessTokenQueue.push({ resolve, reject, type });
    clearTimeout(accessTokenQueueTimeoutId);
    accessTokenQueueTimeoutId = setTimeout(() => {
      let hasAccessTokenPassword = accessTokenQueue.filter(
        current => current.type === OAUTH2_GRANT_TYPES.PASSWORD_CREDENTIALS
      ).length;

      if (Cookie.get(accessTokenPasswordName)) {
        accessTokenQueue.forEach(current => {
          current.resolve(JSON.parse(Cookie.get(accessTokenPasswordName)));
        });
        accessTokenQueue = [];
      } else if (Cookie.get(accessTokenClientName) && !hasAccessTokenPassword) {
        accessTokenQueue.forEach(current => {
          current.resolve(JSON.parse(Cookie.get(accessTokenClientName)));
        });
        accessTokenQueue = [];
      } else {
        accessTokenQueue.forEach(current => {
          current.reject({ status: httpStatusCodeUnauthorized });
        });
      }
    }, 0);
  });
};

const saveToken = (cookieName, data) => {
  let expires = new Date(Date.now() + data.expires_in * 1000);
  Cookie.set(cookieName, JSON.stringify(data), { expires: new Date(expires) });
  if (data.access_token)
    localStorage.setItem(accessTokenRefreshName, data.refresh_token);
};

const connection = (connectionData, token, hasListBody = false) => dispatch => {
  return new Promise((resolve, reject) => {
    const reqType = connectionData.reqType;
    const currentData = connectionData.currentData;
    const currentRequest = Config.API.REQUESTS[reqType];
    let urlAppendix = currentRequest.URL_APPENDIX;
    let baseUrl = currentRequest.USE_AUTH_SERVER
      ? process.env.REACT_APP_AUTH_URL
      : process.env.REACT_APP_API_URL;

    let axiosConfig = {
      customData: {
        reqType: connectionData.reqType,
        currentData: { ...connectionData.currentData },
        useLoader: connectionData.useLoader,
        resolve: connectionData.resolve,
        reject: connectionData.reject,
        retry: connectionData.retry,
      },
    };

    if (Config.DEBUG) baseUrl = Config.PROXY + baseUrl;

    Object.entries(currentData).forEach(([key, entry]) => {
      const currentKey = `{${key}}`;
      const currentValue = currentData[key];

      /* special case for partner to access customer-apis */
      if(key === SELECTED_PARTNER_CUSTOMER_ID) {
        if(currentValue !== undefined) {
          if (urlAppendix.includes(currentKey)) delete currentData[key];
          urlAppendix = urlAppendix.replace(currentKey, currentValue);
        } else {
          urlAppendix = urlAppendix.replace("?customerId={selectedPartnerCustomerId}&", "?");
          urlAppendix = urlAppendix.replace("?customerId={selectedPartnerCustomerId}", "");
          urlAppendix = urlAppendix.replace("&customerId={selectedPartnerCustomerId}", "");
        }
      } else {
        if (urlAppendix.includes(currentKey)) delete currentData[key];
        urlAppendix = urlAppendix.replace(currentKey, currentValue);
      }
    });
    const url = baseUrl + urlAppendix;

    const call = axios.create({
      //url: url,
      baseURL: url,
      method: currentRequest.METHOD,
      responseType: currentRequest.RESPONSE_TYPE ? currentRequest.RESPONSE_TYPE : '',
      validateStatus: () => true,
      headers: {
        Authorization: 'bearer ' + token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': currentRequest?.TYPE === 'MEDIA' ? 'multipart/form-data' :'application/json;charset=UTF-8',
        'Accept-Language': localStorage.getItem('lang') || 'de',
      }
    })

    let body = currentData.body;
    delete currentData.body;
    if( hasListBody ) {
      axiosConfig.data = body;
      axiosConfig.params = { ...currentData, ...currentRequest.DEFAULT_PARAMS };
    } else if (currentRequest.METHOD === 'post' || currentRequest.METHOD === 'put' || currentRequest.METHOD === 'delete') {
      axiosConfig.data = { ...currentData, ...body };
      if (connectionData.hasUrlParams) {
        axiosConfig.params = { ...currentData, ...currentRequest.DEFAULT_PARAMS };
      } else {
        axiosConfig.params = currentRequest.DEFAULT_PARAMS;
      }
    } else {
      axiosConfig.data = body;
      axiosConfig.params = { ...currentData, ...currentRequest.DEFAULT_PARAMS };
    }
    call
      .request(axiosConfig)
      .then(result => {
        let data;

        if (result) {
          if (
            result.status >= httpStatusCodeErrorArea[0] &&
            result.status <= httpStatusCodeErrorArea[1]
          ) {
            if (result.status === httpStatusCodeUnauthorized) {
              if (localStorage.getItem(accessTokenRefreshName)) {
                localStorage.removeItem(accessTokenRefreshName);
                setTimeout(() => {
                  window.location.reload();
                }, 0);
              } else {
                Cookie(accessTokenClientName, undefined);
                Cookie(accessTokenPasswordName, undefined);
              }

              requestFailedQueue.push(axiosConfig);
              clearTimeout(requestQueueTimeoutId);
              requestQueueTimeoutId = setTimeout(() => {
                requestFailedQueue.forEach(current => {
                  if (!current.customData.retry) {
                    apiCallAction(
                      current.customData.reqType,
                      current.customData.currentData,
                      current.customData.useLoader,
                      current.customData
                    )(dispatch);
                  }
                });
                requestFailedQueue = [];
              }, retryRequestDelay);
            } else {
              reject({
                requestData: axiosConfig.customData,
                error: result.status,
                errorData: result.data || {},
              });
            }
          } else if (
            result.status >= httpStatusCodeSuccessArea[0] &&
            result.status <= httpStatusCodeSuccessArea[1]
          ) {
            data = result.data;
            if (axiosConfig && axiosConfig.data) {
              if (axiosConfig.data.email && axiosConfig.data.password) {
                loginCallAction()(dispatch);
              }
            }

            resolve({
              requestData: axiosConfig.customData,
              result: data,
            });
          }
        }
      })
      .catch(() => {
        reject({
          requestData: axiosConfig.customData,
          error: httpStatusCodeNotFound,
          errorData: '',
        });
      });
  });
};

export const apiCallAction =
  (reqType, currentData = {}, useLoader, retryData, hasUrlParams = false, hasListBody = false) =>
    (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        requestQueue.push({
          reqType,
          currentData,
          useLoader,
          resolve,
          reject,
          retry: retryData ? true : false,
          hasUrlParams
        });

        if (useLoader) {
          dispatch(showLoaderAction());
        }

        const state = getState();

        clearTimeout(requestQueueTimeoutId);
        requestQueueTimeoutId = setTimeout(() => {
          startCallAction()(dispatch);
          requestsWithLoaderCounter = requestQueue.filter(
            current => current.useLoader === true
          ).length;
          requestQueue.forEach(currentRequest => {
            //CACHE LOGIC
            /*const requestId = `${currentRequest.reqType}-${JSON.stringify(currentData)}`
          const cachedTimestamp = state.cachedRequests[requestId]
          const reqCacheTime = Config.API.REQUESTS[currentRequest.reqType].CACHE
          if (cachedTimestamp && reqCacheTime && cachedTimestamp > (Date.now() - reqCacheTime * 1000)) {
            doneCallAction()(dispatch)
            return
          }*/

            if (Config.API.REQUESTS[currentRequest.reqType].NEEDS_TOKEN) {
              let loginData = {};
              if (currentData.email && currentData.password) {
                loginData = {
                  user: currentData.email,
                  password: currentData.password,
                };
              }
              accessToken = (async () =>
                await getAccessToken(
                  Config.API.REQUESTS[currentRequest.reqType].GRANT_TYPE,
                  loginData
                ))();
            }

            accessToken
              .then(currentTokenData => {
                connection(
                  currentRequest,
                  currentTokenData.access_token,
                  hasListBody
                )(dispatch)
                  .then(response => {
                    if (
                      response.result.access_token &&
                      response.result.refresh_token
                    )
                      saveToken(accessTokenPasswordName, response.result);

                    response.requestData.resolve(response.result);
                    dispatch({
                      type: response.requestData.reqType,
                      data: response.result,
                    });

                    doneCallAction(/*requestId*/)(dispatch);
                    dispatch(hideLoaderAction());
                    if (retryData && retryData.resolve)
                      retryData.resolve(response.result);
                    if (response.requestData.useLoader) {
                      requestsWithLoaderCounter--;
                      if (requestsWithLoaderCounter === 0)
                        dispatch(hideLoaderAction());
                    }
                  })
                  .catch(response => {
                    dispatch({
                      type: response.requestData.reqType,
                      data: {
                        errorCode: response.error,
                        errorData: response.errorData,
                      },
                    });
                    errorCallAction(response)(dispatch);
                    response.requestData.reject(response);
                  });
              })
              .catch(error => {
                if (error.status === httpStatusCodeUnauthorized) {
                  if (localStorage.getItem(accessTokenRefreshName)) {
                    const url = window.location.href;
                    const theme = url.includes('nfon') ? 'nfon' : 'dts';
                    const currentURL = url.split(theme)[0];
                    const redirectUrl = encodeURIComponent(`${currentURL}${theme}${routes.login.path}`)

                    const lang = localStorage.getItem('lang') || 'de';

                    localStorage.removeItem(accessTokenRefreshName);
                    Cookie(accessTokenClientName, undefined);
                    Cookie(accessTokenPasswordName, undefined);
                    dispatch(logoutCallAction())


                    setTimeout(() => {
                      window.location.href = process.env.REACT_APP_AUTH_URL + MainConfigConstants.API.OAUTH2.REQUESTS.LOGOUT.URL_APPENDIX +
                          "?response_type=code" +
                          "&client_id=" + process.env.REACT_APP_CLIENT_ID +
                          "&post_logout_redirect_uri=" + redirectUrl +
                          "&user_language=" + lang +
                          "&client_style=" + theme;
                    }, 100);
                  } else {
                    errorCallAction(error)(dispatch);
                    //currentRequest.reject();
                  }
                } else {
                  errorCallAction(error)(dispatch);
                  dispatch(hideLoaderAction());
                  currentRequest.reject();
                }
              });

            requestQueue = [];
          });
        }, 0);
      });
    };

export const loginViaAuthServer = (data) => async dispatch => {
  await axios({
    baseURL: process.env.REACT_APP_AUTH_URL + MainConfigConstants.API.OAUTH2.REQUESTS.TOKEN.URL_APPENDIX,
    method: 'post',
    auth: {
      username: process.env.REACT_APP_CLIENT_ID,
      password: process.env.REACT_APP_CLIENT_NOT_SO_SECRET,
    },
    data: new URLSearchParams(data).toString()
  }).then(result => {
    let success;
    if (
      result.status >= httpStatusCodeSuccessArea[0] &&
      result.status <= httpStatusCodeSuccessArea[1]
    ) {
      saveToken(accessTokenPasswordName, result.data);
      success = result.data;
    }
    dispatch(loginCallAction())
    accessTokenQueue.forEach(current => {
      if (success) current.resolve(success);
      else
        current.reject({
          status: result.status,
          type: current.type,
          errorData: result.data.error_description,
          errorCode: result.data.error,
        });

      accessTokenQueue = [];
    });
  })
  dispatch(apiCallAction(ACTION_CONST.API_GET_USER))
}

export const authenticateViaAuthServer = (data) => async dispatch => {
  await axios({
    baseURL: process.env.REACT_APP_AUTH_URL + MainConfigConstants.API.OAUTH2.REQUESTS.TOKEN.URL_APPENDIX,
    method: 'post',
    auth: {
      username: process.env.REACT_APP_CLIENT_ID,
      password: process.env.REACT_APP_CLIENT_NOT_SO_SECRET,
    },
    data: new URLSearchParams(data).toString()
  }).then(result => {
    let success;
    if (
      result.status >= httpStatusCodeSuccessArea[0] &&
      result.status <= httpStatusCodeSuccessArea[1]
    ) {
      saveToken(accessTokenPasswordName, result.data);
      success = result.data;
    }
    dispatch(authenticateCallAction())
    accessTokenQueue.forEach(current => {
      if (success) current.resolve(success);
      else
        current.reject({
          status: result.status,
          type: current.type,
          errorData: result.data.error_description,
          errorCode: result.data.error,
        });

      accessTokenQueue = [];
    });
  })
}