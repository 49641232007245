import { NfoNavLink } from '../navLink.component';
import { ReactComponent as Close } from '../../../../assets/icons/close_white.svg';
import './partnerCustomerSubmenu.scss';
import { routes } from '../../../../constants/routes/routes';
import { useDispatch } from 'react-redux';
import { deleteSelectedPartnerCustomer } from '../../../../redux/actions/partnerCustomers.action';
import { NavLink, useHistory } from 'react-router-dom';
import cs from 'classnames';
import { useTheme } from '../../../../../core/utility/themeContext';
import React from "react";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useSelectedMainContract, useSelectFeatures, useSelectPartnerCustomerDataMenuItem } from "../../../../redux/store";
import { unsetSelectedContract } from "../../../../redux/actions/config.action";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

const NfoPartnerCustomerSubmenu = (props: any) => {
  const { customerName } = props;
  const history = useHistory();
  const theme = useTheme();
  const features = useSelectFeatures();
  const dispatch: AppDispatch = useDispatch();
  const partnerNavigation: any = useSelectPartnerCustomerDataMenuItem();
  const selectedMainContract = useSelectedMainContract();

  const deleteSelectedCustomer = () => {
    dispatch(unsetSelectedContract());
    dispatch(deleteSelectedPartnerCustomer());
    history.push(routes.partnerCustomers.path);
  };

  Array.isArray(partnerNavigation) && partnerNavigation?.map((item: any) => {

    switch (item?.componentName) {
      case 'Standorte':
        item.routeInfo = routes.partnerCustomerSites;
        break;
      case 'Rufnummern':
        item.routeInfo = routes.partnerCustomerNumbers;
        break;
      case 'SIP-Trunk':
        item.routeInfo = routes.partnerCustomerSIPTrunk;
        break;
      case 'Minutenpakete':
        item.routeInfo = routes.partnerCustomerPackages;
        break;
      case 'Flatrate':
        item.routeInfo = routes.partnerCustomerFlatrate;
        break;
      case 'Lizenzen & Services':
        item.routeInfo = routes.partnerCustomerLicenses;
        break;
      case "Nebenstellen":
        item.routeInfo = routes.partnerCustomerExtensions;
        break;
      case 'Security-Einstellungen':
        item.routeInfo = routes.partnerCustomerSecuritySettings;
        break;
    }

    item.routeInfo.navTitle = () => ({
      DE: item.menuItemName.find((o: any) => o.language === 'de')?.value || item?.componentName,
      EN: item.menuItemName.find((o: any) => o.language === 'en')?.value || item?.componentName
    })
  })

  const uniqueMenuItems: any = [];

  const componentNameSet = new Set();

  Array.isArray(partnerNavigation) && partnerNavigation?.forEach(item => {
    if (!componentNameSet.has(item.componentName)) {
      componentNameSet.add(item.componentName);
      //this is because the partner should not change the fraud and sec for a customer................
      if (item.componentName !== 'Security-Einstellungen' && item.componentName !== 'Standorte') {
        uniqueMenuItems.push(item);
      }
    }
  });


  return (
    <>

      <ul className={cs(`partnerCustomerSubmenu-container-le--${theme}`)}>
        <div className={cs('firstLine', `firstLine-le--${theme}`)}>
          <h5>
            <NfoNavLink
              navTitle={customerName}
              {...routes.partnerCustomersOverview}
              internalClassname={cs(`partnerCustomerSubmenu-customerTitle`, `partnerCustomerSubmenu-customerTitle-le--${theme}`)}
            ></NfoNavLink>
          </h5>
          <a className={'pointer'} onClick={deleteSelectedCustomer}>
            <Close className={cs('iconDefault', `iconDefault-le--${theme}`)}/>
          </a>
        </div>
        <NfoNavLink {...routes.partnerCustomersData} activeClass={'active'}/>
        <NfoNavLink {...routes.partnerCustomerSites} activeClass={'active'}/>
        <NfoNavLink {...routes.partnerCustomersContracts} activeClass={'active'}/>
      </ul>
      {selectedMainContract?.contractId &&
        <ul className={cs(`partnerCustomerSubmenu-container-le--${theme}`)}>

          <div className={cs('firstLine', `firstLine-le--${theme}`)}>
            <NavLink to={routes.partnerCustomersContract.path} activeClassName={cs('active', `active-le--${theme}`)}>
              {selectedMainContract.contractName + (selectedMainContract?.productCategory?.id === 14 ? '' : ' (' + selectedMainContract.knumber + ')')}
            </NavLink>
            <a className={'pointer'} onClick={() => {
              dispatch(unsetSelectedContract());
              history.push(routes.partnerCustomersContracts.path)
            }}>
              <Close className={cs('iconDefault', `iconDefault-le--${theme}`)}/>
            </a>
          </div>

          {features.PORTAL_PARTNER_CUSTOMER_ADMINSTRATION_MENU && (Array.isArray(uniqueMenuItems) && uniqueMenuItems?.sort((a: any, b: any) => a.menuItemOrder - b.menuItemOrder).map((nav: any) => {
            return (<NfoNavLink
              {...nav.routeInfo} key={nav.routeInfo.path}
              activeClass={'active'}
            />)
          }))}
        </ul>
      }
    </>
  )
    ;
};

export default NfoPartnerCustomerSubmenu;
