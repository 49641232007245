import { ACTION_CONST } from '../../constants/action.constants'

export const saveSelectedPartnerCustomer = (data) => {
	return {
		type: ACTION_CONST.SAVE_SELECTED_PARTNER_CUSTOMER,
		data: data
	}
}

export const deleteSelectedPartnerCustomer = () => {
	return {
		type: ACTION_CONST.DELETE_SELECTED_PARTNER_CUSTOMER,
	}
}
