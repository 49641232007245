import cs from "classnames";
import { CpxModal } from "../../../../../core/components/modal.component";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "../../../../constants/routes/routes";
import { useDispatch } from "react-redux";
import { deleteStepData, reachedConfirmPage } from "../../../../redux/actions/step.action";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useCurrentOrderType, useOrderEntryBasket, useSelectPartnerCustomer, useSelectUserRole, useStep1Data, useStep2Data } from "../../../../redux/store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ORDER_TYPE, USER_PARTNER, USER_WHOLESALER } from "../../../../constants/configs/config.constants";
import { IPartnerCustomer } from "../../../../constants/types/types.constants";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

interface QuoteParams {
  quoteId?: string;
  segmentId?: string;
}

export const NfoConfirmationModal = ({ confirmModal, setConfirmModal }: any) => {
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.common);
  const translationsModal = useTranslations(TRANSLATIONS.confirmationModal);
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const basketId = useOrderEntryBasket().id;
  const step1Data = useStep1Data();
  const step2Data = useStep2Data();
  const orderType = useCurrentOrderType();
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const role = useSelectUserRole();
  const params = useParams<QuoteParams>();

  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [isOrderLoading, setOrderLoading] = useState(false);
  const [quoteId, setQuoteId] = useState<number|undefined>(undefined);

  useEffect(() => {
    if (confirmModal) saveCustomer();
  }, [confirmModal])

  const saveCustomer = () => {

    const siteAddresses = {
      customDataPortal: {
        siteAddresses: step2Data?.siteAddresses,
      },
    }

    let actionString = ACTION_CONST.API_CREATE_CUSTOMER_CONTRACT;

    let isMigration: boolean = step1Data?.migrationCustomerId && step1Data?.partnerVoNumber;

    if(orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER) {
      if (isMigration) {
        actionString = ACTION_CONST.API_MIGRATE_CUSTOMER;
      } else {
        actionString = ACTION_CONST.API_CREATE_CUSTOMER;
      }
    }else if(orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER_QUOTE) {
      actionString = ACTION_CONST.API_CREATE_CUSTOMER_QUOTE;
    }else if (orderType === ORDER_TYPE.ORDER_EDIT_QUOTE) {
      actionString = ACTION_CONST.API_POST_EDIT_PARTNER_QUOTE;
    }else {
      if(isMigration){
        actionString = ACTION_CONST.API_MIGRATE_CUSTOMER_CONTRACT;
      }
    }
    setOrderLoading(true);
    dispatch(apiCallAction(actionString,
        orderType === ORDER_TYPE.ORDER_EDIT_QUOTE?{basketId,quoteId : params.quoteId, segmentId: params.segmentId} :
      { ...step1Data, ...siteAddresses, basketId, stepper: "CONFIRM_ORDER" })
    ).then((r) => {
      setQuoteId(orderType === ORDER_TYPE.ORDER_EDIT_QUOTE?r:r?.quoteId)
      setOrderLoading(false);
      setSuccessModal(true);
    }).catch((e) => {
      setErrorModal(true);
      setOrderLoading(false);
    })

  }

  const getRoute = () => {
    if(quoteId !== undefined) {
      return routes.partnerQuoteDetail;
    }
    if (role === USER_PARTNER || role === USER_WHOLESALER) {
      if(selectedPartnerCustomer?.id){
        return routes.partnerCustomersContracts;
      } else {
        return routes.partnerCustomers;
      }
    }
    return routes.customerMyContracts;
  }

  const handleModalConfirmation = () => {
    if(quoteId !== undefined){
      setSuccessModal(false);
      dispatch(reachedConfirmPage(true));
      dispatch(deleteStepData());
      history.push(getRoute().path.replace(":quoteId", `${quoteId}`));
    }else{
      setSuccessModal(false);
      dispatch(reachedConfirmPage(true));
      dispatch(deleteStepData());
      history.push(getRoute().path);
    }
  }

  const handleModalError = () => {
    setConfirmModal(false);
    setErrorModal(false);
  }

  return (
    <>
      {/*{confirmationModal && (
        <CpxModal
          onConfirm={() => saveCustomer()}
          cancelText={translations.back()}
          onCancel={() => setConfirmModal(false)}
        >
          <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
            {translations.confirm()}
          </h3>
          <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{translationsModal.correctData()}</p>
        </CpxModal>
      )}*/}
      {isOrderLoading &&
        <CpxModal onConfirmFlag={false}
                  className={""}
        >
          <h3>{translationsModal.pleaseWait()}</h3>
          <p>{translationsModal.yourOrderIsBeingProcessed()}</p>
        </CpxModal>
      }
      {errorModal && (
        <CpxModal
          onConfirm={() => handleModalError()}>
          <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
            {translationsModal.orderError()}
          </h3>
          <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{translationsModal.tryAgain()}</p>
        </CpxModal>
      )}
      {successModal && (
        <CpxModal
          onConfirm={() => handleModalConfirmation()}
        >
          <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
            {orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER_QUOTE ? translationsModal.quoteSuccess() : (orderType === ORDER_TYPE.ORDER_EDIT_QUOTE ? translationsModal.quoteEditSuccess() : translationsModal.increaseSuccess())}
          </h3>
          { step1Data?.migrationCustomerId && <p>
            {translationsModal.migrationLine1()}
            <ol>
              <li>{translationsModal.migrationLine2()}</li>
              <li>{translationsModal.migrationLine3()}</li>
              <li>{translationsModal.migrationLine4()}</li>
              <li>{translationsModal.migrationLine5()}</li>
              <li>{translationsModal.migrationLine6()}</li>
            </ol>
          </p> }
          <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{translationsModal.close()}</p>
        </CpxModal>
      )}
    </>
  );

}
