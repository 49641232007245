import React, { useEffect, useState } from "react";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useFormik } from "formik";
import "./quoteAccountModal.scss";
import { COUNTRY_IDS, ID_ADDRESSES, ID_SALUTATIONS, INPUT_MAX_LENGTHS } from "../../../constants/configs/config.constants";
import { bicCheck, createValidatorSchema, emailCheck, ibanCheck, MaxLengthCheck, requiredCheck } from "../../../utils/validation";
import { trimStringProperties, useTranslations } from "../../../utils/helper/utils";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useSelectBillMedia, useSelectCountries, useSelectError, useSelectPaymentMode, useSelectSalutations } from "../../../redux/store";
import { NfoGenerateFakeDataComponent } from "../../../components/partner/newCustomer/generateFakeData/generateFakeData.component";
import { BillingInformation } from "../../../components/partner/newCustomer/steps/forms/BillingInformation";
import { CpxFormikForm } from "../../../../core/components/formikForm.component";
import { CpxModal } from "../../../../core/components/modal.component";
import cs from "classnames";
import { Quote } from "compax-api";
import { clearError } from "../../../redux/actions/error.action";
import { Alert } from "../../../components/common/Alert/Alert.component";


type AppDispatch = ThunkDispatch<any, any, AnyAction>;

type Props = {
  quote: Quote | undefined;
  setShowModal?: any;
  reloadQuote: any
}

export const NfoQuoteAccountModal = ({setShowModal, quote, reloadQuote}: Props) => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const dispatch: AppDispatch = useDispatch();

  const salutations = useSelectSalutations();
  const countries = useSelectCountries();
  const billMedia = useSelectBillMedia();
  const paymentModes = useSelectPaymentMode();

  const ErrorsOfBE = useSelectError();

  const internalClassname = "quoteAccountModal";


  const [isDifferentBillingAddress, setIsDifferentBillingAddress] = useState(false);
  const [billingAddressSelectedCountry, setBillingAddressSelectedCountry] = useState<number>(NaN);
  const [isSEPA, setIsSEPA] = useState(true);
  const [selectedAccountSalutation, setAccountSelectedSalutation] = useState<number>(NaN);
  const requiredMsg = translation.fieldRequired();

  const salutationOptions = Array.isArray(salutations) ? salutations.map(salutation => {
    return { name: salutation.description, id: salutation.id }
  }) : [];

  const countryOptions = Array.isArray(countries) ? countries.map(country => {
    return { name: country.description, id: country.id }
  }) : [];

  const paymentOptions = Array.isArray(paymentModes) ? paymentModes
      /*.filter(paymentMode => paymentMode.id === 1)*/
      .map(paymentMode => {
        return { name: paymentMode.description, id: paymentMode.id }
      })
    : [];

  const billMediaOptions = Array.isArray(billMedia) ? billMedia.map(billMedia => {
    return { name: billMedia.description, id: billMedia.id }
  }) : [];

  const setAccountSalutation = (val: any) => {
    setAccountSelectedSalutation(val);
  }
  const handleAccountSalutationDropdownChange = (e: any) => {
    formik.handleChange(e);
    setAccountSalutation(+e.target.value);

  }

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_BILL_MEDIA));
    dispatch(apiCallAction(ACTION_CONST.API_GET_PAYMENT_MODE));
  }, []);

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      /* BillingInformation */
      account: {
        accountName: '',
        iban: '',
        bic: '',
        paymentMode: {
          id:  paymentOptions[0]?.id,
        },
        paymentMedia: {
          id: billMediaOptions[-1]?.id,
        },
        billAddress: {
          country: {
            id: countryOptions[-1]?.id,
          },
          street: '',
          houseNumber: '',
          additionalAddress: '',
          zip: '',
          county: '',
          city: '',
          city2: '',
          city3: '',
          district: '',
          province: '',
          salutation: {
            id: salutationOptions[-1]?.id
          },
          companyName1: '',
          companyName2: '',
          email: '',
          addressType: {
            id: ID_ADDRESSES.BILLING,
          },
        },

      },

    },
    validationSchema: createValidatorSchema({
      /* BillingInformation */
      account: createValidatorSchema({
        paymentMedia: createValidatorSchema({
          id: requiredCheck(requiredMsg),
        }),
        paymentMode: createValidatorSchema({
          id: requiredCheck(requiredMsg),
        }),
        accountName: MaxLengthCheck(INPUT_MAX_LENGTHS.account.accountName, isSEPA && requiredCheck(requiredMsg)),
        iban: isSEPA && ibanCheck(requiredMsg, translation.ibanSyntax(), translation.validEUSepa()),
        bic: isSEPA && bicCheck(requiredMsg, translation.bicSyntax()),
        billAddress: createValidatorSchema({
          street: MaxLengthCheck(INPUT_MAX_LENGTHS.address.street, isDifferentBillingAddress && requiredCheck(requiredMsg)),
          houseNumber: MaxLengthCheck(INPUT_MAX_LENGTHS.address.houseNumber,
            (isDifferentBillingAddress &&
              (billingAddressSelectedCountry === COUNTRY_IDS.DE ||
                billingAddressSelectedCountry === COUNTRY_IDS.AT ||
                billingAddressSelectedCountry === COUNTRY_IDS.FR ||
                billingAddressSelectedCountry === COUNTRY_IDS.IT ||
                billingAddressSelectedCountry === COUNTRY_IDS.PL ||
                billingAddressSelectedCountry === COUNTRY_IDS.CH ||
                billingAddressSelectedCountry === COUNTRY_IDS.ES ||
                billingAddressSelectedCountry === COUNTRY_IDS.GB)) && requiredCheck(requiredMsg)),
          zip: MaxLengthCheck(INPUT_MAX_LENGTHS.address.zip, isDifferentBillingAddress && requiredCheck(requiredMsg)),
          city: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city, isDifferentBillingAddress && requiredCheck(requiredMsg)),
          city2: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city2, billingAddressSelectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),
          city3: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city3, billingAddressSelectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),
          district: MaxLengthCheck(INPUT_MAX_LENGTHS.address.district, billingAddressSelectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),
          additionalAddress: MaxLengthCheck(INPUT_MAX_LENGTHS.address.additionalAddress),
          province: MaxLengthCheck(INPUT_MAX_LENGTHS.address.province, (isDifferentBillingAddress && billingAddressSelectedCountry === COUNTRY_IDS.ES) && requiredCheck(requiredMsg)),
          county: MaxLengthCheck(INPUT_MAX_LENGTHS.address.county),
          companyName1: MaxLengthCheck(selectedAccountSalutation !== ID_SALUTATIONS.COMPANY ? INPUT_MAX_LENGTHS.customerData.firstName : INPUT_MAX_LENGTHS.customerData.companyName1, isDifferentBillingAddress && requiredCheck(requiredMsg)),
          companyName2: MaxLengthCheck(selectedAccountSalutation !== ID_SALUTATIONS.COMPANY ? INPUT_MAX_LENGTHS.customerData.lastName : INPUT_MAX_LENGTHS.customerData.companyName2,
            (isDifferentBillingAddress && selectedAccountSalutation !== ID_SALUTATIONS.COMPANY) && requiredCheck(requiredMsg)),
          email: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.email, emailCheck(translation.emailSyntax())),
          country: createValidatorSchema({
            id: isDifferentBillingAddress && requiredCheck(requiredMsg),
          }),
          salutation: createValidatorSchema({
            id: isDifferentBillingAddress && requiredCheck(requiredMsg),
          })
        }),

      }),
    }),
    onSubmit: async (values: any) => {
      dispatch(clearError())

      var tempValues = {...values};

      if (!isSEPA) {
        tempValues.account.accountName = null;
        tempValues.account.iban = null;
        tempValues.account.bic = null;
        tempValues.account.email = null;
      }

      if (!isDifferentBillingAddress) {
        tempValues.account.billAddress = null;
      }

      trimStringProperties(tempValues);
      tempValues.account.iban = tempValues.account.iban.replace(" ",'')
      tempValues.account.bic = tempValues.account.bic.replace(" ",'')

      dispatch(apiCallAction(ACTION_CONST.API_POST_QUOTE_ACCOUNT, {...tempValues.account, quoteId: quote?.id})).then((res) => {
        reloadQuote();
      })
    }

  });

  const setBillingData = (data: any) => {
    formik.values.account.accountName = data.accountName
    formik.values.account.iban = data.iban
    formik.values.account.bic = data.bic
    formik.values.account.paymentMedia.id = billMediaOptions[0]?.id
    formik.values.account.paymentMode.id = paymentOptions[0]?.id
  }

  return (
    <>
      <CpxFormikForm handleSubmit={formik.handleSubmit}
                     initialValues={formik.initialValues}
                     id={'quoteAccount-modal'}
      >
        <CpxModal onCancel={() => {
          dispatch(clearError())
          setShowModal(false)
        }} formId={'quoteAccount-modal'}>
          <div className={cs(`${internalClassname}`)}>
            {ErrorsOfBE.errorData && ErrorsOfBE.errorData.length > 0 && <Alert errors={ErrorsOfBE.errorData}/>}
          <NfoGenerateFakeDataComponent salutationOptions={salutationOptions} countryOptions={countryOptions} setBillingData={setBillingData}/>
          <BillingInformation values={formik.values} handleChange={formik.handleChange}
                              touched={formik.touched} errors={formik.errors}
                              paymentOptions={paymentOptions} billingMediumOptions={billMediaOptions}
                              salutationOptions={salutationOptions}
                              countryOptions={countryOptions}
                              selectedCountry={billingAddressSelectedCountry}
                              setSelectedCountry={setBillingAddressSelectedCountry}
                              handleSalutation={handleAccountSalutationDropdownChange}
                              isSEPA={isSEPA}
                              setIsSEPA={setIsSEPA}
                              isDifferentBillingAddress={isDifferentBillingAddress}
                              setIsDifferentBillingAddress={setIsDifferentBillingAddress}
          />
          </div>
        </CpxModal>
      </CpxFormikForm>
    </>
  );
};
