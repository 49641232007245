import { useSelectContracts, useSelectedMainContract, useSelectFeatures } from '../../../redux/store';
import { FEATURES_FLAG, ID_CONTRACT_SIP_TRUNK, ID_CONTRACT_TELEPHONE_CONNECTION, } from '../../../constants/configs/config.constants';
import { NfoTelephoneConnection } from '../telephoneConnection/telephoneConnection.component';
import './mainContractDetails.scss';
import { PortalContractData } from 'compax-api';
import { NfoMainContractInfo } from '../mainContract/mainContract.component';
import { NfoSipTrunkBox } from '../sipTrunk/sipTrunk.component';
import { useTheme } from '../../../../core/utility/themeContext';
import cs from 'classnames';
import { IContracts } from '../../../constants/types/types.constants';
import { CpxButton } from "../../../../core/components/button.component";
import React, { useEffect, useState } from "react";
import { ChangeProductModal, pages } from "../productChange/changeProductModal/changeProductModal.component";
import { useDispatch } from "react-redux";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { NfoPartnerBusinessInternetOption } from "../../partner/customer/contract/partnerBusinessInternetOptions/partnerBusinessInternetOptions.component";

type Props = {
  mainContract: PortalContractData;
};

export const NfoMainContract = ({ mainContract }: Props) => {
  const contracts = useSelectContracts() as IContracts;
  const theme = useTheme();
  const internalClassName = 'mainContractDetails';
  const features = useSelectFeatures();
  const dispatch = useDispatch();
  const [changeModal, setChangeModal] = useState({ isShowing: false, page: pages.SELECT_OPTION });
  const selectedMainContract = useSelectedMainContract();

  const translations = useTranslations(TRANSLATIONS.changeProductModal)
  const sipTrunkOptions = contracts.filter(
    entry =>
      entry.componentOf === mainContract.id &&
      entry?.serviceType?.id + '' === ID_CONTRACT_SIP_TRUNK
  );

  const businessInternetOptions =
    contracts !== undefined &&
    contracts.filter(entry =>
      entry.componentOf === mainContract.id &&
      mainContract?.productCategory?.id === 14
    );


  const telephoneConnections = contracts.filter(
    entry =>
      entry.componentOf === mainContract.id &&
      entry?.serviceType?.id + '' === ID_CONTRACT_TELEPHONE_CONNECTION
  );

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_PRODUCT_CHANGE}));
  }, []);

  return (
    <>
      {changeModal.isShowing && <ChangeProductModal changeModal={changeModal} setChangeModal={setChangeModal}/>}
      <article
        className={cs(
          internalClassName,
          `${internalClassName}-contractName-le--${theme}`
        )}
      >
        <div className={cs(`${internalClassName}-header`, `${internalClassName}-header-le--${theme}`)}>
          <h4 className={cs(`${internalClassName}-contractTitle-le--${theme}`)}>
            {mainContract.contractName} </h4>
          {features.PORTAL_PRODUCT_CHANGE && mainContract?.productWithPossibleSuccessor && selectedMainContract?.status?.id === 1 &&
            <CpxButton buttonType={"white"} onClick={() => setChangeModal({ isShowing: true, page: 1 })}>{translations.upgrade()}</CpxButton>}
        </div>


        <NfoMainContractInfo key={mainContract.id} contract={mainContract}/>
      </article>
      {!!sipTrunkOptions &&
        sipTrunkOptions.map((contract, index) => (
          <NfoSipTrunkBox
            key={contract.id}
            contract={contract}
            countSip={index}
          />
        ))}
      {!!telephoneConnections &&
        telephoneConnections.map(contract => (
          <NfoTelephoneConnection key={contract.id} contract={contract}/>
        ))}
      {!!businessInternetOptions &&
        businessInternetOptions.map((businessInternetOption, index) => {
          return (
            <NfoPartnerBusinessInternetOption
              key={businessInternetOption.id}
              businessInternetOption={businessInternetOption}
              contracts={contracts}
            />
          );
        })}
    </>
  );
};
