import { CommunicationHistory, Quote, WorkFlowInfo } from 'compax-api';
import { IAccounts, IAction, IPartnerCustomer, IPartnerCustomerReducer, IPartnerCustomers, ISelectedPartnerCustomer } from '../../constants/types/types.constants';
import { ACTION_CONST } from '../../constants/action.constants';

const initialState: IPartnerCustomerReducer = {
  partnerCustomers: [] as IPartnerCustomers,
  partnerQuotes: [] as Array<Quote>,
  selectedPartnerCustomer: ({
    partnerCustomer: {} as IPartnerCustomer,
    accounts: [] as IAccounts,
    contract: [],
    extensions: [],
    menuItems: [],
    numbers: [],
    address: [],
    chargeSum: {},
    communications: [] as CommunicationHistory,
    workflowsinfo: [] as WorkFlowInfo,
  } as unknown) as ISelectedPartnerCustomer,
};

function partnerCustomersReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.API_GET_PARTNER_CUSTOMERS:
      state = {
        ...state,
        partnerCustomers: action.data.map((customer: any) => {
          return {
            ...customer,
            companyName: customer.companyName ? customer.companyName : customer.firstName + ' ' + customer.lastName
          }
        }),
      };
      break;
    case ACTION_CONST.SAVE_SELECTED_PARTNER_CUSTOMER:
    case ACTION_CONST.API_GET_PARTNER_CUSTOMERS_DATA:
      state = {
        ...state,
        selectedPartnerCustomer: {
          ...state.selectedPartnerCustomer,
          partnerCustomer: {
            ...action.data,
            companyName: action.data.companyName ? action.data.companyName : action.data.firstName + ' ' + action.data.lastName
          },
        },
      };
      break;
    case ACTION_CONST.DELETE_SELECTED_PARTNER_CUSTOMER:
      state = {
        ...state,
        selectedPartnerCustomer: {
          partnerCustomer: {} as IPartnerCustomer,
        },
      };
      break;
    case ACTION_CONST.API_GET_PARTNER_CUSTOMERS_ACCOUNTS:
      state = {
        ...state,
        selectedPartnerCustomer: {
          ...state.selectedPartnerCustomer,
          accounts: action.data,
        },
      };
      break;
    case ACTION_CONST.API_GET_PARTNER_CUSTOMERS_EXTENSIONS_DATA:
      state = {
        ...state,
        selectedPartnerCustomer: {
          ...state.selectedPartnerCustomer,
          extensions: action.data,
        },
      };
      break;
    case ACTION_CONST.API_GET_CUSTOMERS_MENU_ITEMS:
      state = {
        ...state,
        selectedPartnerCustomer: {
          ...state.selectedPartnerCustomer,
          menuItems: action.data,
        },
      };
      break;
    case ACTION_CONST.API_GET_PARTNER_CUSTOMERS_CLI_DATA:
      state = {
        ...state,
        selectedPartnerCustomer: {
          ...state.selectedPartnerCustomer,
          numbers: action.data,
        },
      };
      break;
    case ACTION_CONST.API_GET_PARTNER_CUSTOMERS_ADDRESSES_DATA:
      state = {
        ...state,
        selectedPartnerCustomer: {
          ...state.selectedPartnerCustomer,
          address: action.data,
        },
      };
      break;
    case ACTION_CONST.API_GET_PARTNER_CUSTOMERS_CHARGE_SUM:
      state = {
        ...state,
        selectedPartnerCustomer: {
          ...state.selectedPartnerCustomer,
          chargeSum: action.data,
        },
      };
      break;
    case ACTION_CONST.API_GET_PARTNER_CUSTOMERS_COMMUNICATIONS:
      state = {
        ...state,
        selectedPartnerCustomer: {
          ...state.selectedPartnerCustomer,
          communications: action.data,
        },
      };
      break;
    case ACTION_CONST.API_GET_PARTNER_CUSTOMERS_WORKFLOWS:
      state = {
        ...state,
        selectedPartnerCustomer: {
          ...state.selectedPartnerCustomer,
          workflowsinfo: action.data,
        },
      };
      break;
    case ACTION_CONST.API_GET_PARTNER_QUOTES:
      state = {
        ...state,
        partnerQuotes: action.data,
      };
      break;
  }

  return state;
}

export default partnerCustomersReducer;
