import { LookupValue, TelcoData } from "compax-api";

export const terminateContractsBody = (
  contractIds: [],
  terminationDate: Date,
  client: number,
  lastUser: number,
  terminationWorkflowId?: number

) => {
  return {
    contracts: contractIds,
    header: {
      client: client,
      externalOrderId: 'string',
      lastUser: lastUser,
      marketingCampaignId: 'string',
      orderEntryOrganisation: {
        distributionsChannel: 0,
        salesPartner: 0,
      },
      salesOrganisation: {
        distributionsChannel: 0,
        salesPartner: 0,
      },
      source: 2,
    },
    requestedTerminationDate: terminationDate,
    terminationReasonId: 0,
    terminationWorkflowId: terminationWorkflowId
  };
};

export const editNotesBody = (
  notes: string
) => {
  return {
    notes: notes,
    };
};

export const saveOverridePrice = (
  value: number | undefined
) => {
  return {
    value: value,
  };
};

export const addOptionsCustomDataBody = (
  customData: any,
) => {
  return {
    customData: customData
  };
};

export const releaseQuoteBody = (
  personalizedMessage: string,
  customDocumentName: string,
) => {
  return {
    personalizedMessage: personalizedMessage,
    customDocumentName: customDocumentName,
  };
};

export const answerQuoteBody = (
    customerComment: string,
) => {
  return {
    customerComment: customerComment
  };
};

export const addTelcoData = (telcoData : TelcoData) => {
  return telcoData;
}

export const addServiceIds = (serviceIds : number[]) => {
  return serviceIds;
}

export const addDocument = (uuids : string[] | undefined, documentId: number, documentType: LookupValue | undefined, fileName: string) => {
  return {
    uuids: uuids,
    document: {
      id: documentId,
      documentName: fileName,
      documentType: documentType
    }
  };
}




