import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { saveActivationDate, saveCurrentStep, updateProductSipTrunkToValidatedAndSetUuid } from "../../../../redux/actions/step.action";
import { NfoCustomerWrapperComponent } from "../wrapper/newCustomerWrapper.component";
import { useTheme } from "../../../../../core/utility/themeContext";
import cs from "classnames";
import { CLOUDYA_CENTREXX_PRODUCT_SEGMENT_IDS, ID_CONTRACT_SIP_TRUNK, NCONNECT_VOICE_PRODUCT_SEGMENT_IDS, ORDER_TYPE } from "../../../../constants/configs/config.constants";
import { NfoActivationDate } from "../configureProduct/activationDate/activationDate.component";
import { useCurrentOrderType, useCurrentStep, useOEProductOptions, useOrderEntryBasket, useStep1Data, useStep3Data, useStepperActivationDate } from "../../../../redux/store";
import { NfoCentrexxOptions } from "../configureProduct/centrexxOptions/centrexxOptions";
import { useFormik } from "formik";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { NfoSipTrunkOption } from "../configureProduct/sipTrunkOption/sipTrunkOption";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { clearError } from "../../../../redux/actions/error.action";
import { Basket } from "compax-api";
import { Alert } from "../../../common/Alert/Alert.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoCustomerConfigureProductStep = () => {
    const translationsStepper = useTranslations(TRANSLATIONS.stepper.newCustomer);
    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();
    const currentStep = useCurrentStep();
    const basket: Basket = useOrderEntryBasket();
    const stepOneData = useStep1Data();
    const optionsGroups = useOEProductOptions();
    const step3Data = useStep3Data();
    const stepperActivationDate = useStepperActivationDate();
    const orderType = useCurrentOrderType();

    const [activationDate, setActivationDate] = useState();
    const [error, setError] = useState('');

    if (!stepperActivationDate) {
        dispatch(saveActivationDate(basket?.entries[step3Data?.activeIndex]?.startDtRequested));
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: () => {
            const options = basket?.entries[step3Data?.activeIndex]?.options;
            const productIdOfSipTrunk = optionsGroups.find((item: any) => item.basketOptions.find((option: any) => option?.product?.productType?.id === +ID_CONTRACT_SIP_TRUNK))?.basketOptions[0]?.product?.id;
            if(productIdOfSipTrunk && !options?.find(option => option.product.id === productIdOfSipTrunk)){
                setError(translationsStepper.chooseOneSipTrunkIdiot());
                return;
            }
            if (basket && Array.isArray(options) && options.length > 0) {

                let errorArray = options?.map((o: any, index: number) => {


                    return dispatch(apiCallAction(ACTION_CONST.API_VALIDATE_CUSTOMER_DATA, {
                        ...stepOneData,
                        stepper: "VALIDATE_BASKET_OPTION",
                        basketId: basket?.id,
                        validateBasketEntryIndex: step3Data?.activeIndex,
                        validateBasketEntryOptionIndex: index,
                        isQuote: [ORDER_TYPE.ORDER_NEW_CUSTOMER_QUOTE, ORDER_TYPE.ORDER_EDIT_QUOTE].includes(orderType)
                    })).then(() => {
                        if (productIdOfSipTrunk && productIdOfSipTrunk === o.product.id) {
                            dispatch(updateProductSipTrunkToValidatedAndSetUuid(o.uuid));
                        }
                        return 'yes';

                    }).catch((e) => {
                        console.log("some error")
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        return Promise.reject('no');
                    })
                });

                Promise.all(errorArray)
                  .then(results => {
                      dispatch(saveCurrentStep(currentStep + 1));
                      dispatch(saveActivationDate(activationDate));
                      dispatch(clearError());
                      setError('')
                  })
                  .catch(error => console.log("some error occured"))

            }
        }
    })

    return (
        <>
            <NfoCustomerWrapperComponent
                title={translationsStepper.productConfiguration(step3Data?.activeProduct?.displayValue + ' - ' + (1 + Object.values(basket.entries).filter(entry => entry.product.id === step3Data?.activeProduct?.id).findIndex(entry => entry.uuid === step3Data?.activeEntryUuid)))}
                subtitle={translationsStepper.productConfigurationSubTitle()}
            >
                <>
                    <CpxFormikForm handleSubmit={formik.handleSubmit}
                                   initialValues={formik.initialValues}
                                   id={'new-customer-form'}
                    >
                        <NfoActivationDate setActivationDate={setActivationDate} className={"orderEntry"}/>
                        {error && <Alert errors={[error]}/>}
                    </CpxFormikForm>
                    <div className={cs(`options`, `options-le--${theme}`)}>
                        {CLOUDYA_CENTREXX_PRODUCT_SEGMENT_IDS.includes(step3Data?.activeProduct && step3Data?.activeProduct?.productSegment && step3Data?.activeProduct?.productSegment?.id) && <NfoCentrexxOptions/>}
                        {NCONNECT_VOICE_PRODUCT_SEGMENT_IDS.includes(step3Data?.activeProduct && step3Data?.activeProduct?.productSegment && step3Data?.activeProduct?.productSegment?.id)
                            && <NfoSipTrunkOption setError={setError}/>}
                    </div>
                </>
            </NfoCustomerWrapperComponent>
        </>
    );
};
