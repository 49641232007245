import cs from "classnames";
import { CpxButton } from "../../../../../core/components/button.component";
import { ReactComponent as Add } from "../../../../assets/icons/add.svg";
import { CpxPaginatedTable } from "../../../../../core/components/paginatedTable.component";
import React, { useEffect, useState } from "react";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { CpxIcon } from "../../../../../core/components/icon.component";
import { ICONS, ORDER_TYPE, TELEPHONE_CONNECTIONS_IDS, USER_CUSTOMER, USER_PARTNER, USER_WHOLESALER } from "../../../../constants/configs/config.constants";
import { useTheme } from "../../../../../core/utility/themeContext";
import "./documentsTable.scss";
import { useDispatch } from "react-redux";
import { CpxButtonDropDown } from "../../../../../core/components/buttonDropdown.component";
import { useCurrentOrderType, useOrderEntryBasket, useSelectLang, useSelectUserRole, useStep1Data, useStep2Data, useStep5Data, useStepperBillingInformationSelection } from "../../../../redux/store";
import { AddressPortal, Basket, BasketEntry, BasketEntryDocument } from "compax-api";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ProofOfSiteModal } from "./proofOfSiteModal.component";
import { allReqDocumentUploaded, saveStep5Data } from "../../../../redux/actions/step.action";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { NfoEmptyState } from "../../../../../core/emptyState.component";


type AppDispatch = ThunkDispatch<any, any, AnyAction>;

type CountryAndAreaCodes = {
  countryId: number,
  evidenceProvided?: string[]
}


export const NfoDocumentsTable = () => {
  const theme = useTheme();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsCommon = useTranslations(TRANSLATIONS.common);
  const dispatch: AppDispatch = useDispatch();
  const internalClassName = "documentsTable";
  const hiddenFileInput = React.useRef(null as any);
  const basket: Basket = useOrderEntryBasket();
  const step5Data = useStep5Data();
  const billingInformationSelection = useStepperBillingInformationSelection();
  const step1Data = useStep1Data();
  const step2Data = useStep2Data();
  const orderType = useCurrentOrderType();
  const addresses = [step1Data.mainAddress, ...step2Data?.siteAddresses]
  const evidenceProvided = addresses
    .map((siteAddress : AddressPortal) : CountryAndAreaCodes  => ({countryId: siteAddress.country.id,  evidenceProvided: siteAddress.evidenceProvided }))
    .reduce((acc: Map<number, Set<string>>, obj: CountryAndAreaCodes) => {
      const key = obj.countryId;
      if (!acc.get(key)) {
        acc.set(key, new Set<string>());
      }
      obj.evidenceProvided?.forEach(areaCode => acc.get(key)?.add(areaCode))
      return acc;
    }, new Map<number, Set<string>>());






  const [showModal, setShowModal] = useState<boolean>(false);
  const [newCallNumberSiteAddress, setNewCallNumberSiteAddress] = useState([] as any[]);
  const [documentOptions, setDocumentOptions] = useState([] as any[]);
  const [documents, setDocuments] = useState(step5Data as { label: string, type: string, fileName: any, documentId?: any, uploaded: boolean, documentTypeId?: number, entries: [string] }[]);
  const [index, setIndex] = useState(0);
  const telephoneOptions = Object.values(basket.entries).flatMap((entry: BasketEntry) => entry?.options?.filter(e => TELEPHONE_CONNECTIONS_IDS.includes(e.product.id)) || []);
  const callNumbers = telephoneOptions?.flatMap((i: BasketEntry) => i.options?.filter(op => op.telcoData?.siteAddress)) || [];
  const currentLanguage = selectCurrentLanguage(useSelectLang());
  const userRole = useSelectUserRole();


  function isContractDocUploaded(entry: BasketEntry) {
    return documents.find(d => d.type === "contract" && d.entries.includes(entry.uuid ||''));
  }

  function areAllContractDocumentUploaded() {
    return userRole === USER_CUSTOMER || userRole === USER_WHOLESALER || Object.values(basket.entries).every(entry => isContractDocUploaded(entry));
  }

  function isSepaDocumentUploaded() {
    return documents.find(d => d.type === "sepa");
  }

  function areAllDocumentsUploaded(): boolean {
    return areAllContractDocumentUploaded() && Object.values(newCallNumberSiteAddress).length === 0;
  }

  function existsUnselectedSiteAddress() {

    let unselected = false;

    for (const group of Object.values(newCallNumberSiteAddress)) {
      const foundObject = group.find((obj: any) => !obj.selected);
      if (foundObject) {
        unselected = foundObject;
        break;
      }
    }

    return unselected;
  }

  const findDropDownItems = () => {

    if (userRole === USER_PARTNER) {
      setDocumentOptions((pre) => [...pre.filter(p => p.name !== "contract"),
        ...Object.values(basket.entries).filter((entry: BasketEntry) => !isContractDocUploaded(entry))
          .map((entry: BasketEntry) => { return {name: "contract", label: translation.documentsTypeContract() + " (" + entry.product.description + " - " + (Object.values(basket.entries)
              .filter(e => entry.product.id ===  e.product.id)
              .findIndex(e => e.uuid === entry.uuid) + 1) + ") *", subLabel: entry.product.description + " - " + (Object.values(basket.entries).filter(e => entry.product.id ===  e.product.id)
              .findIndex(e => e.uuid === entry.uuid) + 1), documentTypeId: 8, uuid: entry.uuid }; },)])
    }

    if (billingInformationSelection?.isSEPA && orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER) {
      if (!isSepaDocumentUploaded()) {
        setDocumentOptions((pre) => [...pre.filter(p => p.name !== "sepa"), { name: "sepa", label: translation.documentsTypeSEPA(), subLabel: "", documentTypeId: 10, entries: [Object.values(basket.entries)[0]?.uuid] }]);
      } else {
        setDocumentOptions((pre) => [...pre.filter(p => p.name !== "sepa")])
      }

    }

    if (existsUnselectedSiteAddress()) {
      setDocumentOptions((pre) => [...pre.filter(p => p.name !== "site"), { name: "site", label: translation.documentsTypeSite() + " *" }]);

    } else {
      setDocumentOptions((pre) => [...pre.filter(p => p.name !== "site")])
    }
  }

  useEffect(() => {
    findCallNumber2SiteAddress();
    findDropDownItems();
  }, [currentLanguage, basket.entries[0]?.documents, basket.entries[0]?.options])

  useEffect(() => {
    findDropDownItems();
    dispatch(allReqDocumentUploaded(areAllDocumentsUploaded()));
  }, [newCallNumberSiteAddress]);

  const findCallNumber2SiteAddress = () => {

    setNewCallNumberSiteAddress([]);

    let tempObjectArray = new Array<any>();

    callNumbers && callNumbers?.map((cn: any) => {

      if (!cn.documents.filter((d: BasketEntryDocument) => d?.documentType?.id !== 7).find((d: any) => d.id)) {

        let temp: any;
        let tempAreaCode: string = '';

        if (cn.telcoData?.newPhoneNumberBlock) {
          //case phone number block......
          temp = cn.telcoData?.newPhoneNumberBlock?.countryCode + ' ' + cn.telcoData?.newPhoneNumberBlock?.areaCode;
          tempAreaCode = cn.telcoData?.newPhoneNumberBlock?.areaCode;

        } else if (cn.telcoData?.newPhoneNumber) {
          //case phone number....
          temp = cn.telcoData?.newPhoneNumber.countryCode + ' ' + cn.telcoData?.newPhoneNumber.areaCode;
          tempAreaCode = cn.telcoData?.newPhoneNumber.areaCode;
        } else if (cn.telcoData?.lnpData) {
          //case porting.....
          let lnpData = cn.telcoData?.lnpData;

          if (lnpData.portPhoneNumbers) {
            //case porting multiple Subscriber Numbers......
            temp = lnpData.portPhoneNumbers[0].countryCode + ' ' + lnpData.portPhoneNumbers[0].areaCode;
            tempAreaCode = lnpData.portPhoneNumbers[0].areaCode;

          } else {
            //case porting number Block......
            temp = lnpData.portPhoneNumberBlock.countryCode + ' ' + lnpData.portPhoneNumberBlock.areaCode;
            tempAreaCode = lnpData.portPhoneNumberBlock.areaCode;
          }
        } else if(cn.telcoData?.newLnpPhoneNumberBlock) {
          temp = cn.telcoData?.newLnpPhoneNumberBlock.countryCode + ' ' + cn.telcoData?.newLnpPhoneNumberBlock.areaCode;
          tempAreaCode = cn.telcoData?.newLnpPhoneNumberBlock.areaCode;
        }


        let tempObject = {
          areaCode: tempAreaCode,
          areaCountryCode: temp,
          uuid: cn?.uuid,
          siteAddress: cn?.telcoData?.siteAddress,
          selected: false
        };
        tempObjectArray.push(tempObject);
      }

    });


    const grouped = tempObjectArray.reduce((acc, obj) => {
      if(!evidenceProvided.get(obj.siteAddress.country.id)?.has(obj.areaCode)){
        const key = obj.areaCountryCode;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
      }
      return acc;
    }, {});


    setNewCallNumberSiteAddress(grouped);
  }

  const handleClickFakeButton = (index: number) => {
    setIndex(index)
    hiddenFileInput?.current?.click();
  };

  const handleUploadFile = (event: any) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    const newDocuments = [...documents];
    formData.append('file', fileUploaded);

    //api call to upload file
    dispatch(apiCallAction(ACTION_CONST.API_DOCUMENTS_POST_DOCUMENT, { documentTypeId: newDocuments[index].documentTypeId, file: fileUploaded, convertPdfTo: "" }, true)).then(
      (res: any) => {
        //api call to add to basket
        dispatch(apiCallAction(ACTION_CONST.API_BASKET_PUT_DOCUMENT,
          {
            basketId: basket?.id,
            id: res,
            documentName: fileUploaded.name,
            documentType: { id: newDocuments[index].documentTypeId },
            entries: newDocuments[index]?.entries
          }
        ));

        newDocuments[index].fileName = fileUploaded.name;
        newDocuments[index].documentId = res;
        newDocuments[index].uploaded = true;
        setDocuments(newDocuments);
        dispatch(saveStep5Data(newDocuments));
        findCallNumber2SiteAddress();

      }
    );
  };

  const handleDeleteDocument = (event: any, index: number) => {

    const currentDocument = documents[index];

    const basketDocumentRemoveList = [{ entries: currentDocument.entries, documentId: +currentDocument.documentId }];
    //delete api call from server
    currentDocument.documentId && dispatch(apiCallAction(ACTION_CONST.API_DOCUMENTS_DELETE_DOCUMENT, { documentId: currentDocument.documentId }));

    //delete api call from basket
    if (currentDocument.documentId) {

      dispatch(apiCallAction(ACTION_CONST.API_BASKET_DELETE_DOCUMENT,
        {
          basketId: basket?.id,
          basketDocumentRemoves: basketDocumentRemoveList
        }
      ));
    }

    const newDocuments = [...documents];
    newDocuments.splice(index, 1)
    setDocuments(newDocuments);
    dispatch(saveStep5Data(newDocuments));
    findCallNumber2SiteAddress();

  }

  const handleDownloadFile = (e: any, index: number) => {
    const currentDocument = documents[index];


    currentDocument.documentId &&
    dispatch(apiCallAction(ACTION_CONST.API_DOCUMENTS_DOWNLOAD_DOCUMENT, { documentId: currentDocument.documentId })).then((res) => {
      const link = document.createElement("a");
      link.href = `data:${res.mimeType};base64,${res.file}`;
      link.download = res.documentName;
      link.click();
      link.remove();

    });
  }

  const documentsTableRowData = (document: any, index: number) => ({
    id: "" + index,
    cellData: [
      <div>
        <p className={cs(`${internalClassName}-label`, `${internalClassName}-label-le--${theme}`)}>{document.type === "contract" ? translation.documentsTypeContract() + " *" : document.label}</p>
        <span className={cs(`${internalClassName}-subLabel`, `${internalClassName}-subLabel-le--${theme}`)}>{document.subLabel}</span>
      </div>,
      (document?.uploaded) ?
        <a target="_blank" onClick={e => handleDownloadFile(e, index)} className={cs("tableFirstChild ", `tableFirstChild-font-le--${theme}`)}>{document.fileName} </a> :
        <><input onChange={handleUploadFile} type='file' ref={hiddenFileInput} accept="application/pdf" hidden/><CpxButton onClick={() => handleClickFakeButton(index)}>{translation.documentsUploadFile()}</CpxButton></>,
      <div className={cs(`${internalClassName}-actions`, `${internalClassName}-actions-le--${theme}`)}>
        <CpxButton className={"iconOnly"} type={'button'} onClick={e => handleDeleteDocument(e, index)}><CpxIcon icon={ICONS.DELETE}/></CpxButton>
      </div>
    ],
  });


  const handleAddNewOption = (option: any) => {
    if (option.name === "site") {
      setShowModal(true);
    } else if (option.name == "sepa") {
      addNewDocument(option);
    } else  {
      addNewDocument({ ...option, entries: [option?.uuid] });
    }

  }

  const addNewDocument = (option: any) => {
    setDocuments(cur => [...cur, { type: option.name, label: option.label, subLabel: option.subLabel, fileName: null, uploaded: false, documentTypeId: option.documentTypeId, entries: option?.entries }]);
  }

  return (
    <>
      {showModal && <ProofOfSiteModal setShowModal={setShowModal} addNewDocument={addNewDocument} siteAddresses={newCallNumberSiteAddress}/>}
      <article className={cs(`${internalClassName}`, `${internalClassName}-le--${theme}`)}>
        <div className={cs(`${internalClassName}-container`, `${internalClassName}-container-le--${theme}`)}>
          <h4 className={cs(`${internalClassName}-title`, `${internalClassName}-title-le--${theme}`)}>
            {translation.titleOfDocumentsStep()}
          </h4>
          {<CpxButtonDropDown className={"buttonIcons"} type={'button'} handleClickOption={handleAddNewOption} options={documentOptions} disabled={documentOptions.length === 0 || !!documents.find(doc => !doc.uploaded)}>
            <Add/>
            {translationsCommon.add()}
          </CpxButtonDropDown>}
        </div>
        <NfoEmptyState
          data={documents}
          subtitle={translation.documentsEmptyStateSubTitle()}
          pageTitle={translation.titleOfDocumentsStep()}
        >
          <CpxPaginatedTable
            id={internalClassName}
            tableHeader={[
              translation.titleOfDocumentsStep(),
              translation.documentsTableHeadingFileName(),
              "",
            ]}
            tableData={documents?.map(documentsTableRowData)}
          />
        </NfoEmptyState>
      </article>
    </>
  )
}
