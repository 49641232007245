import cs from "classnames";
import React from "react";
import "./sendQuoteModal.scss";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useTheme } from "../../../../core/utility/themeContext";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { createValidatorSchema, MaxLengthCheck } from "../../../utils/validation";
import { clearError } from "../../../redux/actions/error.action";
import { CpxFormikForm } from "../../../../core/components/formikForm.component";
import { CpxInputWithLabel } from "../../../../core/components/inputWithLabel.component";
import { useSelectError } from "../../../redux/store";
import { Alert } from "../../../components/common/Alert/Alert.component";
import { CpxModal } from "../../../../core/components/modal.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { CpxTextAreaWithLabel } from "../../../../core/components/textAreaWithLabel.component";
import { useToast } from "../../../components/common/header/toast/toast.component";

type Props = {
  setShowModal: any;
  releaseQuote: any;
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;


export const SendQuoteModal = ({ setShowModal, releaseQuote}: Props) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const translation = useTranslations(TRANSLATIONS.createQuote);
  const internalClassName = "textFieldModal";
  const ErrorsOfBE = useSelectError();
  const toast = useToast();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      personalizedMessage: "",
      customDocumentName: ""
    },
    validationSchema: createValidatorSchema({
      personalizedMessage: MaxLengthCheck(4000),
      customDocumentName: MaxLengthCheck(55),
    }),
    onSubmit: async (values: any) => {
      releaseQuote(values)
    }
  });





  return (
    <><CpxFormikForm handleSubmit={() => {}}
                     initialValues={formik.initialValues}
                     id={'textField-modal-data-form'}>
      <CpxModal
        confirmText={translation.send()}
        onCancel={() => {
          setShowModal(false);
          dispatch(clearError());
        }
        }
        formId={'textField-modal-data-form'}
        onConfirm={formik.handleSubmit}
      >
        <h4 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {translation.sendQuote()}
        </h4>
        <div className={`${internalClassName}-content`}>
          <CpxTextAreaWithLabel
            id="personalizedMessage"
            name={"personalizedMessage"}
            className={`${internalClassName}-input`}
            onChange={formik.handleChange}
            value={formik.values.personalizedMessage}
            error={formik.touched?.personalizedMessage && formik.errors?.personalizedMessage}
          >
            <p className='activation-date-content--text'>{translation.personalizedMessage()}</p>
          </CpxTextAreaWithLabel>
        </div><div className={`${internalClassName}-content`}>
        <CpxInputWithLabel
          id="customDocumentName"
          name={"customDocumentName"}
          className={`${internalClassName}-input`}
          onChange={formik.handleChange}
          value={formik.values.customDocumentName}
          error={formik.touched?.customDocumentName && formik.errors?.customDocumentName}
        >
          <p className='activation-date-content--text'>{translation.optionalDocumentName()}</p>
        </CpxInputWithLabel>
      </div>

        {ErrorsOfBE?.errorData && ErrorsOfBE?.errorData?.length > 0 && <Alert errors={ErrorsOfBE?.errorData}/>}
      </CpxModal>
    </CpxFormikForm></>
  )
}
