import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import "./quoteItemsTable.scss";
import React from "react";
import cs from "classnames";
import { QuoteItem, QuoteItemCharge, QuoteSegment } from "compax-api";
import { useTheme } from "../../../../core/utility/themeContext";
import { CpxInputWithLabel } from "../../../../core/components/inputWithLabel.component";
import { CpxIcon } from "../../../../core/components/icon.component";
import { ICONS } from "../../../constants/configs/config.constants";
import { CpxButton } from "../../../../core/components/button.component";
import {amount, currency} from "../../../../core/utils";


type Props = {
  segment: QuoteSegment;
  saveValue?: Function;
  chargesEditable?: boolean;
  updateChargesValue?: any;
  updateChargeEdit?: any;
  editCharges?: Map<number, boolean>;
  editedChargesValue?: Map<number, number>;
}
export const NfoQuoteItemsTable = ({segment, saveValue,updateChargesValue,updateChargeEdit, editCharges, editedChargesValue, chargesEditable = false}:Props) => {

  const translations = useTranslations(TRANSLATIONS.createQuote);
  const theme = useTheme();
  const internalClassName = "quoteItemsTable";

  const getAllCharges = (item: any) => {
    const optionCharges = item.options.flatMap((option: any) => getAllCharges(option)) || []
    return [...item.charges, ...optionCharges]
  }

  const segmentCurrency = segment.items?.flatMap(getAllCharges).find(() => true)?.charge?.currency?.description

  const getOneTimeCharge = (item: QuoteItem): QuoteItemCharge | undefined => {
    return item.charges && item.charges.find(charge => charge.charge?.chargeMode?.id === 1)
  }

  const getMonthlyCharge = (item: QuoteItem): QuoteItemCharge | undefined => {
    return item.charges && item.charges.find(charge => charge.charge?.chargeMode?.id === 2 || charge.charge?.chargeMode?.id === 4)
  }

  const renderChargeTableColumns = (charge: QuoteItemCharge | undefined) => {
    return (<>
        {charge ?
          (editCharges && editCharges.get(Number(charge?.id)) && chargesEditable) ?
            <td>
              <div className={cs(`${internalClassName}-charge-edit`)}>
                <CpxInputWithLabel
                  id={"contractNotes"}
                  name={"contractNotes"}
                  value={editedChargesValue && editedChargesValue.get(Number(charge.id))}
                  onChange={(event: any) => updateChargesValue(Number(charge?.id), event.target.value)}
                  type="number"
                  step="0.01"
                  autoFocus
                  onKeyDown={e => e.key === 'Enter' && saveValue && saveValue(charge?.id)}
                />
                <CpxButton className={'iconOnly'} type={'button'} onClick={(event) => saveValue && saveValue(charge?.id)}><CpxIcon icon={ICONS.CHECK}/></CpxButton>
                <CpxButton className={cs('iconOnly', `${internalClassName}-cross-btn ${internalClassName}-cross-btn-le--${theme}`)} type={'button'} onClick={() => updateChargeEdit(Number(charge?.id), false)}><CpxIcon icon={ICONS.CROSS}/></CpxButton>
              </div>
            </td> :
            <td>
              {currency(charge?.amountNetOverride !== undefined ? charge.amountNetOverride : charge?.amountNet, charge?.charge?.currency?.description)}
              {chargesEditable &&
                <CpxButton className={"iconOnly"} type={'button'} onClick={() => updateChargeEdit(Number(charge?.id), true)}>
                  <CpxIcon icon={ICONS.EDIT}/>
                </CpxButton>
              }
            </td> :
          <td/>}
        <td>{charge?.discount ? `${charge?.discount}%` : ''}</td>
      </>
    )
  }

  const renderQuoteHierarchy = (item: any, level: number) => {
    const oneTimeCharge = getOneTimeCharge(item);
    const monthlyCharge = getMonthlyCharge(item);

    return (
      <>
        <tr className={cs(
          `paginatedTable-firstRow-le--${theme}`,
          `paginatedTable-row-le--${theme}`)}>
          <td className={cs(`hierarchy-level-${level}`)}>{item.product?.displayValue}</td>
          <td>{item.quantity}</td>
          {renderChargeTableColumns(oneTimeCharge)}
          {renderChargeTableColumns(monthlyCharge)}

        </tr>
        {item?.options && item?.options.map((option: any) => renderQuoteHierarchy(option, level + 1))}
      </>

    )

  }


  return (
    <>
      <article className={cs('article-box', `${internalClassName}`)}>
        <table className={'paginatedTable'}>
          <thead className={`paginatedTable-tableHeader-le--${theme}`}>
          <tr>
            <th className="product-name">{translations.product()}</th>
            <th className="product-quantity">{translations.quantity()}</th>
            <th className="product-price-onetime">{translations.oneTimeCharge()}</th>
            <th>{translations.discount()}</th>
            <th className="product-price-monthly">{translations.monthlyCharge()}</th>
            <th>{translations.discount()}</th>
          </tr>
          </thead>
          <tbody className={`paginatedTable-body-le--${theme}`}>
          {segment.items && segment.items.map(item => {
            return renderQuoteHierarchy(item, 0);
          })}
          </tbody>
          <tfoot>
          <tr className={`sum-row-le--${theme}`}>
            <td>{translations.sum()}</td>
            <td/>
            <td>{currency(segment?.oneTimeTotalNet, segmentCurrency)}</td>
            <td/>
            <td>{currency(segment?.monthlyTotalNet, segmentCurrency)}</td>
            <td/>
          </tr>
          </tfoot>
        </table>
      </article>
    </>
  );
};
