import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelectApiCallStatus } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { apiCallAction, authenticateViaAuthServer } from "../../../redux/actions/apiCall.action";
import OAUTH2_GRANT_TYPES from '../../../constants/main.oauth2-grant-types';
import { ID_STATUS_QUOTES, LOCALSTORAGE_ACCESSTOKEN } from "../../../constants/configs/config.constants";
import { deleteSelectedPartnerCustomer } from "../../../redux/actions/partnerCustomers.action";
import Cookie from "cookies-js";
import { ACTION_CONST } from "../../../constants/action.constants";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Quote } from "compax-api";
import { useToast } from "../../../components/common/header/toast/toast.component";
import { ToastTypes } from "../../../constants/types/types.constants";
import cs from "classnames";
import "./quoteAnswer.scss"
import { CpxTitle } from "../../../../core/components/title/title.component";
import { useTheme } from "../../../../core/utility/themeContext";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { NfoContainer } from "../../../container/container.component";
import { CpxButton } from "../../../../core/components/button.component";
import { switchLanguage } from "../../../../core/uiLanguage/langStore";
import { TextAreaModal } from "../../partner/customers/textAreaModal/textAreaModal.component";
import { answerQuoteBody } from "../../../utils/helper/requestBodyHelper";
import { NfoQuoteOverview } from "../../partner/quoteDetail/quoteOverview.component";
import { NfoQuoteItemsTable } from "../../partner/quoteDetail/quoteItemsTable.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;


export const NfoQuoteAnswerPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const accessToken = Cookie.get(LOCALSTORAGE_ACCESSTOKEN)
  const dispatch: AppDispatch = useDispatch();
  const currentURL = window.location.href.split('?')[0];
  const isAuthenticated = useSelectApiCallStatus().authenticated && localStorage.getItem(LOCALSTORAGE_ACCESSTOKEN);
  const token = query.get('token');
  const quoteId = query.get('quoteId');
  const [quote, setQuote] = useState<Quote|undefined>();
  const toast = useToast();
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.createQuote);
  const internalClassName = "quoteAnswer";
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [error, setError] = useState('')

  useEffect(() => {
    if (!accessToken) {
      let formObject = {
        grant_type: OAUTH2_GRANT_TYPES.CLIENT_CREDENTIALS,
        redirect_uri: currentURL,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_NOT_SO_SECRET,
      }
      dispatch(authenticateViaAuthServer(formObject))

      // set language
      const key = navigator.languages[0].slice(0, 2) === 'de' ? 'DE' : 'EN'
      dispatch(switchLanguage({ language: key }));
      localStorage.setItem("lang", key.toLowerCase());

    }
    dispatch(deleteSelectedPartnerCustomer());
  }, [currentURL, dispatch])

  useEffect(() => {
    if(accessToken) {
      dispatch(apiCallAction(ACTION_CONST.API_GET_CUSTOMER_QUOTE, {token: token, quoteId: quoteId})).then((res) => {
        setQuote(res);
      }).catch((res) => {
        toast.showToast(ToastTypes.ERROR, translations.quoteNotValidTitle(), translations.quoteNotValidDetail(), Infinity)
      })
    }
  }, [accessToken]);

  const handleDownloadFile = (e: any) => {
    quote?.quoteDocument?.id &&
    dispatch(apiCallAction(ACTION_CONST.API_GET_CUSTOMER_QUOTE_DOCUMENT, {token: token, quoteId: quoteId})).then((res) => {
      const link = document.createElement("a");
      link.href = `data:${res.mimeType};base64,${res.file}`;
      link.download = res.documentName;
      link.click();
      link.remove();
    });
  }

  const acceptQuote = (values:string) => {
    dispatch(apiCallAction(ACTION_CONST.API_POST_CUSTOMER_QUOTE_ACCEPT, {...answerQuoteBody(values), token: token, quoteId: quoteId})).then((res) => {
      setQuote(res);
      setShowAcceptModal(false)
      toast.showToast(ToastTypes.SUCCESS, translations.quoteAccepted(), translations.replyForwarded(), 5000)
    }).catch((res) => {
      toast.showToast(ToastTypes.ERROR, translations.error(), translations.contactPartner(), Infinity)
    })
  }

  const rejectQuote = (values:string) => {
    dispatch(apiCallAction(ACTION_CONST.API_POST_CUSTOMER_QUOTE_REJECT, {...answerQuoteBody(values), token: token, quoteId: quoteId})).then((res) => {
      setQuote(res);
      setShowRejectModal(false)
      toast.showToast(ToastTypes.SUCCESS, translations.quoteRejected(), translations.replyForwarded(), 5000)
    }).catch((res) => {
      toast.showToast(ToastTypes.ERROR, translations.error(), translations.contactPartner(), Infinity)
    })
  }

  return (
    <NfoContainer>
      <>
        {showAcceptModal && <TextAreaModal
          setShowModal={setShowAcceptModal}
          onSubmition={acceptQuote}
          error={error}
          setError={setError}
          title={translations.acceptQuote()}
          textFieldLabel={translations.optionalMessage()}
          buttonTitle={translations.accept()}
        />}
        {showRejectModal && <TextAreaModal
          setShowModal={setShowRejectModal}
          onSubmition={rejectQuote}
          error={error}
          setError={setError}
          title={translations.rejectQuote()}
          textFieldLabel={translations.optionalMessage()}
          buttonTitle={translations.reject()}
        />}
        {quote !== undefined && <div className={internalClassName}>
          <div className={cs('quotesTitle')}>
            <CpxTitle
              title={quote?.name || translations.addQuote()}
              internalClassName={internalClassName}
            />
          </div>
          <NfoQuoteOverview quote={quote}/>

          {quote?.personalizedMessage &&
            <>
              <h5 className={"message-title"}>{translations.messageFromPartner()}</h5>
              <div className={"message"}>{quote.personalizedMessage}</div>
            </>
          }
          {quote.quoteDocument &&
            <>
              <h5 className={"message-title"}>{translations.quoteDocument()}</h5>
              <div className={cs(`${internalClassName}-downloadDocument`, `${internalClassName}-downloadDocument-le--${theme}`)}><a target="_blank" onClick={e => handleDownloadFile(e)}
                                                                                                                                    className={cs("tableFirstChild", `tableFirstChild-font-le--${theme}`)}>{quote.quoteDocument.description}</a></div>
            </>
          }

          {quote?.segments && quote?.segments.map(segment =>
            (
              <NfoQuoteItemsTable segment={segment}/>
            )
          )}

          <div className={"answer-buttons"}>
            {quote?.status?.id === ID_STATUS_QUOTES.READY_FOR_SUBMISSION &&
              <>
                <CpxButton onClick={() => setShowRejectModal(true)} buttonType={"error"}>{translations.reject()}</CpxButton>
                <CpxButton onClick={() => setShowAcceptModal(true)}>{translations.accept()}</CpxButton>
              </>
            }
          </div>

        </div>}
      </>
    </NfoContainer>
  );
};
