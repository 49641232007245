import cs from "classnames";
import React from "react";
import "./textAreaModal.scss";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useTheme } from "../../../../../core/utility/themeContext";
import { createValidatorSchema, MaxLengthCheck } from "../../../../utils/validation";
import { clearError } from "../../../../redux/actions/error.action";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { useSelectError } from "../../../../redux/store";
import { Alert } from "../../../../components/common/Alert/Alert.component";
import { CpxModal } from "../../../../../core/components/modal.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { CpxTextAreaWithLabel } from "../../../../../core/components/textAreaWithLabel.component";

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  textFieldLabel?: string;
  onSubmition: Function;
  error: string;
  showOverview?: boolean;
  setError: any;
  title: string;
  buttonTitle: string;
}

export enum PAGES {
  ID_INPUT,
  OVERVIEW
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>;


export const TextAreaModal = ({ setShowModal, textFieldLabel, onSubmition, error, setError, title, buttonTitle }: Props) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const internalClassName = "textFieldModal";
  const ErrorsOfBE = useSelectError();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      inputValue: ""
    },
    validationSchema: createValidatorSchema({
      inputValue: MaxLengthCheck(4000),
    }),
    onSubmit: async (values: any) => {
      onSubmition(values.inputValue);
    }
  });


  return (
    <>
      <CpxFormikForm handleSubmit={() => {}}
                     initialValues={formik.initialValues}
                     id={'textField-modal-data-form'}>
      <CpxModal
        confirmText={buttonTitle}
        onCancel={() => {
          setShowModal(false);
          dispatch(clearError());
        }
        }
        formId={'textField-modal-data-form'}
        onConfirm={formik.handleSubmit}
      >
        <h4 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {title}
        </h4>
        <div className={`${internalClassName}-content`}>
          <CpxTextAreaWithLabel
            id="inputValue"
            name={"inputValue"}
            className={`${internalClassName}-input`}
            onChange={formik.handleChange}
            value={formik.values.inputValue}
            error={formik.touched?.inputValue && formik.errors?.inputValue}
          >
            {textFieldLabel && <p className='activation-date-content--text'>{textFieldLabel}</p>}
          </CpxTextAreaWithLabel>
        </div>


        <div className={'errorField'}>{error}</div>
        {ErrorsOfBE?.errorData && ErrorsOfBE?.errorData?.length > 0 && <Alert errors={ErrorsOfBE?.errorData}/>}
      </CpxModal>
    </CpxFormikForm>
    </>
  )
}
