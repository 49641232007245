import { CpxDropdownWithLabel } from "../../../../../../../core/components/dropdown.component";
import { CpxInputWithLabel } from "../../../../../../../core/components/inputWithLabel.component";
import React from "react";
import { AreaCodeError, SelectOption } from "../../../../../../constants/types/types.constants";
import { CpxIcon } from "../../../../../../../core/components/icon.component";
import { ICONS } from "../../../../../../constants/configs/config.constants";
import { useTheme } from "../../../../../../../core/utility/themeContext";
import { CpxButton } from "../../../../../../../core/components/button.component";
import { getCountryById, useTranslations } from "../../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../../constants/transitions/uiTranslations";
import { InputError } from "../../../../../../../core/components/inputError.component";

type Props = {
    formik: any;
    siteAddressOptions: Array<SelectOption>;
    index: number;
    deleteBlock: Function;
    errorsArray: Array<AreaCodeError | false> | any;
}

export const AreaCodeItem = ({formik, siteAddressOptions, index, deleteBlock, errorsArray}: Props) => {
    const internalClassName = "numbers-modal";
    const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
    const theme = useTheme();

    function getUndocumentedSiteAddress() {
        const siteAddress = formik.values?.configurations[index]?.undocumentedSiteAddress
        return `${siteAddress.companyName}, ${siteAddress.street}, ${siteAddress.zip} ${siteAddress.city}, ${getCountryById(+siteAddress.country.id)}`
    }

    return (
        <>
            <div className={`${internalClassName}-address-wrapper`}>
                {
                    formik.values.configurations[index].cpe ?
                        <CpxInputWithLabel
                            id={`configurations[${index}].selectedAddress.id`}
                            name={`configurations[${index}].selectedAddress.id`}
                            disabled={true}
                            className={"undocumentedAddress"}
                            value={getUndocumentedSiteAddress()}
                            onChange={formik.handleChange}
                            error={formik.errors?.configurations && formik.errors?.configurations[index]?.selectedAddress?.id}
                        >
                            {translation.site()}
                        </CpxInputWithLabel>
                        :
                        <CpxDropdownWithLabel
                            id={`configurations[${index}].selectedAddress.id`}
                            name={`configurations[${index}].selectedAddress.id`}
                            required={true}
                            options={siteAddressOptions}
                            value={formik.values?.configurations && formik.values?.configurations[index]?.selectedAddress?.id}
                            onChange={formik.handleChange}
                            error={formik.errors?.configurations && formik.errors?.configurations[index]?.selectedAddress?.id}
                        >
                            {translation.site()}
                        </CpxDropdownWithLabel>
                }
                {index > 0 && <CpxButton className={`${internalClassName}-delete-icon ${internalClassName}-delete-icon-le--${theme} iconOnly`} type={'button'} onClick={() => deleteBlock(index)}><CpxIcon icon={ICONS.DELETE} /></CpxButton>}
            </div>
            <CpxInputWithLabel
                className={`${internalClassName}-areaCode-input`}
                id={`configurations[${index}].areaCode`}
                name={`configurations[${index}].areaCode`}
                min="0"
                step="1"
                type="string"
                onChange={formik.handleChange}
                value={formik.values?.configurations && formik?.values?.configurations[index]?.areaCode}
                error={formik.errors?.configurations && formik?.touched?.configurations && formik?.errors?.configurations[index]?.areaCode}
            >
                {translation.areaCode()} *
            </CpxInputWithLabel>
            {errorsArray[index] && errorsArray[index]?.message?.requestData?.reqType === 'API_VALIDATE_AREA_CODE' && <InputError errorMessage={errorsArray[index]?.message?.errorData[0]} />}
        </>
    )
}