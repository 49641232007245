import { ACTION_CONST } from "../../constants/action.constants";
import { ORDER_TYPE } from "../../constants/configs/config.constants";

const initialState = {
    step1Data: [],
    step2Data: {
        formData: {},
        siteAddresses: [],
    },
    step3Data: [],
    step4Data: {
        activationDate: '',
        productOptions: [],
        optionsOfOptions: {},
        optionConfigurations: [],
        productOptionsForSipTrunk: [],
    },
    step5Data: [],
    step6Data: [],
    allDocumentsUploaded: false,
    showHintModalAgain: false,
    isProductSelected: false,
    currentStep: 1,
    optionalSteps: [8],
    orderType: ORDER_TYPE.ORDER_NEW_CUSTOMER,
    hasReachedConfirmPage: false,
    billingInformationSelection: {
        isSEPA: true,
        isDifferentBillingAddress: false,
    },
    activationDateSelection: {
        isPlanned: false,
    },
    basket: {},
    products: [],
    step1IsDifferentContactPerson: false,
}

const setValidatedStatus = (state, action, status) => state.step4Data.productOptions.map(productOption => {
    productOption.basketOptions.map(basketOption => {
        if (basketOption.product?.id === action.data) {
            basketOption.validated = status;
            return basketOption
        }
        return basketOption
    })
    return productOption
})

const setValidatedStatusAndSetUuid = (state, action, status) => {
    let foundOptionWithNullUuid = state.step4Data.productOptionsForSipTrunk.find(sipTrunk => sipTrunk.uuid === action.data);
    if (!foundOptionWithNullUuid) {
        foundOptionWithNullUuid = state.step4Data.productOptionsForSipTrunk.find(sipTrunk => sipTrunk.uuid === null);
    }

    foundOptionWithNullUuid.sipTrunkOpt.validated = status;
    foundOptionWithNullUuid.uuid = action.data;
    return [...state.step4Data.productOptionsForSipTrunk]
}

const setInValidatedStatusAndSetUuid = (state, action, status) => {
    const foundOptionWithUuid = state.step4Data.productOptionsForSipTrunk.find(sipTrunk => sipTrunk.uuid === action.data);
    foundOptionWithUuid.sipTrunkOpt.validated = status;
    return [...state.step4Data.productOptionsForSipTrunk]
}

function stepReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_CONST.SAVE_CURRENT_STEP:
            return state = {
                ...state,
                currentStep: action.data,
            }
        case ACTION_CONST.SAVE_CURRENT_ORDER_TYPE:
            return state = {
                ...state,
                orderType: action.data,
            }
        case ACTION_CONST.REACHED_CONFIRM_PAGE:
            return state = {
                ...state,
                hasReachedConfirmPage: true,
            }
        case ACTION_CONST.ALL_REQ_DOCUMENTS_UPLOADED:
            return state = {
                ...state,
                allDocumentsUploaded: action.data,
            }
        case ACTION_CONST.IS_PRODUCT_SELECTED:
            return state = {
                ...state,
                isProductSelected: action.data,
            }
        case ACTION_CONST.STORE_STEP_1_DATA:
            return state = {
                ...state,
                step1Data: action.data,
            }
        case ACTION_CONST.STORE_STEP_2_DATA:
            return state = {
                ...state,
                step2Data: action.data,
            }
        case ACTION_CONST.STORE_STEP_3_DATA:
            return state = {
                ...state,
                step3Data: action.data,
            }
        case ACTION_CONST.STORE_STEP_4_DATA:
            return state = {
                ...state,
                step4Data: action.data,
            }
        case ACTION_CONST.STORE_STEP_5_DATA:
            return state = {
                ...state,
                step5Data: action.data,
            }
        case ACTION_CONST.STORE_STEP_6_DATA:
            return state = {
                ...state,
                step6Data: action.data,
            }
        case ACTION_CONST.API_POST_PREPARE_EDIT_QUOTE:
            return state = {
                ...state,
                basket: action.data.basket,
                step2Data: {
                    formData: {},
                    siteAddresses: action.data.addresses,
                }
            }

        case ACTION_CONST.API_BASKET_CREATE_ORDER_ENTRY:
        case ACTION_CONST.API_BASKET_PUT_PRODUCT:
        case ACTION_CONST.API_PUT_OE_PRODUCT_OPTIONS:
        case ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_CUSTOM_DATA:
        case ACTION_CONST.API_BASKET_PUT_PRODUCT_OPTIONS_TELCO_DATA:
        case ACTION_CONST.API_BASKET_ADD_TELCO_DATA:
        case ACTION_CONST.API_BASKET_DELETE_OPTION_BY_UUID:
        case ACTION_CONST.API_DELETE_OE_PRODUCT_OPTIONS:
        case ACTION_CONST.API_BASKET_DELETE_PRODUCT:
        case ACTION_CONST.API_BASKET_PUT_ACTIVATION_DATE:
        case ACTION_CONST.API_BASKET_DELETE_DOCUMENT:
        case ACTION_CONST.API_BASKET_PUT_DOCUMENT:
        case ACTION_CONST.API_BASKET_PREPARE_ORDER_ENTRY:
        case ACTION_CONST.API_BASKET_DELETE_OPTION_INDEX:
        case ACTION_CONST.API_BASKET_SET_LANGUAGE:
        case ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_ADD_CHANNEL_TO_BASKET:
        case ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_ACCEPT_MIGRATION_HINTS:
            return action.data.errorCode ? state : state = {
                ...state,
                basket: action.data,
            }
        case ACTION_CONST.API_POST_OE_MIGRATION_CUSTOMER_ADD_CALLNUMBERS_TO_BASKET:
            return action.data.errorCode ? state : state = {
                ...state,
                basket: action.data.basket,
            }
        case ACTION_CONST.DELETE_STEP_4:
            return state = {
                ...state,
                step4Data: {
                    productOptionsForSipTrunk: []
                },
            }
        case ACTION_CONST.DELETE_STEP_DATA:
            return state = {
                ...state,
                step1Data: [],
                step2Data: {
                    formData: {},
                    siteAddresses: [],
                },
                step3Data: [],
                step4Data: {
                    productOptionsForSipTrunk: [],
                },
                step5Data: [],
                step6Data: [],
                currentStep: 1,
                optionalSteps: [8],
                hasReachedConfirmPage: false,
                billingInformationSelection: {
                    isSEPA: true,
                    isDifferentBillingAddress: false,
                },
                activationDateSelection: {
                    isPlanned: false,
                },
                basket: {},
                orderType: ORDER_TYPE.ORDER_NEW_CUSTOMER,
                step1IsDifferentContactPerson: false,
            }
        case ACTION_CONST.BILLING_INFORMATION_SELECTION:
            return state = {
                ...state,
                billingInformationSelection: action.data,
            }
        case ACTION_CONST.STEP_1_IS_DIFFERENT_CONTACT_PERSON:
            return state = {
                ...state,
                step1IsDifferentContactPerson: action.data,
            }
        case ACTION_CONST.ACTIVATION_DATE_SELECTION:
            return state = {
                ...state,
                activationDateSelection: action.data,
            }
        case ACTION_CONST.API_GET_OE_PRODUCTS:
        case ACTION_CONST.API_GET_QUOTE_PRODUCTS:
            return {
                ...state,
                products: action.data,
            };
        case ACTION_CONST.STORE_ACTIVATION_DATE:
            return state = {
                ...state,
                step4Data: {
                    ...state.step4Data,
                    activationDate: action.data,
                }
            }
        case ACTION_CONST.API_GET_OE_PRODUCT_OPTIONS:
            return state = {
                ...state,
                step4Data: {
                    ...state.step4Data,
                    productOptions: action.data.optionGroups,
                }
            }
        case ACTION_CONST.SET_PRODUCT_OPTION_TO_VALIDATED:
            return state = {
                ...state,
                step4Data: {
                    ...state.step4Data,
                    productOptions: setValidatedStatus(state, action, true)
                }
            }
        case ACTION_CONST.SET_PRODUCT_OPTION_TO_INVALIDATED:
            return state = {
                ...state,
                step4Data: {
                    ...state.step4Data,
                    productOptions: setValidatedStatus(state, action, false)
                }
            }
        case ACTION_CONST.SET_SPI_TRUNK_PRODUCT_OPTION_TO_INVALIDATED:
            return state = {
                ...state,
                step4Data: {
                    ...state.step4Data,
                    productOptionsForSipTrunk: setInValidatedStatusAndSetUuid(state, action, false)
                }
            }
        case ACTION_CONST.UPDATE_PRODUCT_SIP_TRUNK_TO_VALIDATED_AND_SET_UUID:
            return state = {
                ...state,
                step4Data: {
                    ...state.step4Data,
                    productOptionsForSipTrunk: setValidatedStatusAndSetUuid(state, action, true)
                }
            }
        case ACTION_CONST.SAVE_OE_OPTIONS_OF_OPTION:
            const newOptionsOfOptions = {...state.step4Data.optionsOfOptions, ...action.data}
            return state = {
                ...state,
                step4Data: {
                    ...state.step4Data,
                    optionsOfOptions: newOptionsOfOptions
                }
            }
        case ACTION_CONST.DELETE_OE_OPTIONS_OF_OPTION_WITH_PRODUCT_ID:
            const optionsOfOptions = Object.keys(state.step4Data.optionsOfOptions).filter(p => p !== action.data.toString()).reduce((obj, key) => {
                obj[key] = state.step4Data.optionsOfOptions[key]
                return obj;
            }, {});

            return state = {
                ...state,
                step4Data: {
                    ...state.step4Data,
                    optionsOfOptions: {...optionsOfOptions}
                }
            }
        case ACTION_CONST.SAVE_OE_CONFIGURATIONS_OF_OPTION:
            const newConfigurationsOfOption = {...state.step4Data.optionConfigurations, ...action.data}
            return state = {
                ...state,
                step4Data: {
                    ...state.step4Data,
                    optionConfigurations: newConfigurationsOfOption
                }
            }
        case ACTION_CONST.SET_SHOW_HINT_MODAL_AGAIN:
            return state = {
                ...state,
                showHintModalAgain: action.data
            }
        case ACTION_CONST.SAVE_OE_NEW_SIP_TRUNK_OPTION:
            return state = {
                ...state,
                step4Data: {
                    ...state.step4Data,
                    productOptionsForSipTrunk: action.data
                }
            }
        case ACTION_CONST.DELETE_OE_SIP_TRUNK_OPTION:
            const newSIPTrunkArray = [...state.step4Data.productOptionsForSipTrunk].filter(p => p.uuid !== action.data)

            return state = {
                ...state,
                step4Data: {
                    ...state.step4Data,
                    productOptionsForSipTrunk: newSIPTrunkArray
                }
            }
        default:
            return state;
    }
}

export default stepReducer
