import cs from "classnames";
import { CpxModal } from "../../../../../core/components/modal.component";
import React from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import { useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "../../../../constants/routes/routes";
import { useDispatch } from "react-redux";
import { deleteStepData } from "../../../../redux/actions/step.action";
import { useCurrentOrderType, useSelectPartnerCustomer } from "../../../../redux/store";
import { ORDER_TYPE } from "../../../../constants/configs/config.constants";

interface QuoteParam  {
  quoteId?: string;
}

export const NfoCancelModal = ({ setCancelModal }: any) => {
  const theme = useTheme();
  const translations = useTranslations(TRANSLATIONS.common);
  const history = useHistory();
  const dispatch = useDispatch();
  const orderType = useCurrentOrderType();
  const selectedCustomer = useSelectPartnerCustomer();
  const params = useParams<QuoteParam>();

  const confirmCancelation = () => {
    dispatch(deleteStepData());
    setCancelModal(false);

    const route = getRoute();
    if(orderType === ORDER_TYPE.ORDER_EDIT_QUOTE){
      history.push(route.replace(":quoteId", `${params.quoteId}`))
    }else{
      history.push(route);
    }
  }

  const getRoute = () => {
    if(orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER){
      return routes.partnerCustomers.path;
    }
    if(orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER_QUOTE) {
      return routes.partnerCreateQuote.path;
    }
    if(orderType === ORDER_TYPE.ORDER_EDIT_QUOTE){
      return routes.partnerQuoteDetail.path;
    }
    if(selectedCustomer?.id){
      return routes.partnerCustomersContracts.path;
    }
    return routes.customerMyContracts.path;
  }

  return (
    <>
      <CpxModal
        onConfirm={() => confirmCancelation()}
        confirmText={translations.cancel()}
        cancelText={translations.back()}
        onCancel={() => setCancelModal(false)}
      >
        <h3 className={cs('modalTitle', `modalTitle-le--${theme}`)}>
          {translations.cancel()}
        </h3>
        <p className={cs(`modalInfoText modalInfoText-le--${theme}`)}>{translations.cancelText()}</p>
      </CpxModal>
    </>
  );

}
