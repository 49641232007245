import { ProjectRoute } from '../../../core/types';
import { Languages } from '../../../core/uiLanguage/types';
import { TRANSLATIONS } from '../transitions/uiTranslations';
import { CENTREXX_PRODUCT_IDS, NCONNECT_VOICE_PRODUCT_IDS, USER_CUSTOMER, USER_CUSTOMER_TELEPHONY, USER_PARTNER, USER_WHOLESALER, USER_WHOLESALER_EVN, } from '../configs/config.constants';
import { NfoLoginPage } from '../../pages/login/login.page';
import { NfoCallbackPage } from '../../pages/callback/callback.page';
import { NfoSetNewPasswordPage } from '../../pages/setNewPassword/setNewPassword.page';
import { NfoBillsPage } from '../../pages/customer/bills/bills.page';
import { NfoContractDetailsPage } from '../../pages/customer/contractDetails/contractDetails.page';
import { NfoItemizedPage } from '../../pages/customer/itemized/itemized.page';
import { NfoMyContractPage } from '../../pages/customer/myContract/myContract.page';
import { NfoMyContractsPage } from '../../pages/customer/myContracts/myContracts.page';
import { NfoMyDataPage } from '../../pages/customer/myData/myData.page';
import { NfoNumbersPage } from '../../pages/customer/numbers/numbers.page';
import { NfoPackagesPage } from '../../pages/customer/packages/packages.page';
import { NfoProfilePage } from '../../pages/customer/profile/profile.page';
import { NfoSettingsPage } from '../../pages/customer/settings/settings.page';
import { NfoSitesPage } from '../../pages/customer/sites/sites.page';
import { NfoCustomersPartnerPage } from '../../pages/partner/customers/customers.page';
import { NfoTasksPartnerPage } from "../../pages/partner/customers/tasks.page";
import { NfoLicensesPage } from '../../pages/customer/licenses/licenses.page';
import { NfoExtensionsPage } from '../../pages/customer/extensions/extensions.page';
import { NfoFlatratePage } from '../../pages/customer/flatrate/flatrate.page';
import { NfoCustomersOverviewPartnerPage } from '../../pages/partner/customers/overview/overview.page';
import { NfoCustomersContractPartnerPage } from '../../pages/partner/customers/contract/contract.page';
import { NfoCustomersContactHistoryPartnerPage } from '../../pages/partner/customers/contactHistory/contactHistory.page';
import { NfoCustomersDataPartnerPage } from '../../pages/partner/customers/data/data.page';
import { NfoCustomersOrdersPartnerPage } from '../../pages/partner/customers/orders/orders.page';
import { NfoCustomersTasksPartnerPage } from '../../pages/partner/customers/tasks/tasks.page';
import { NfoMyAccountPage } from "../../pages/partner/myAccount/myAccount.page";
import { NfoSipTrunkPage } from "../../pages/customer/sipTrunk/sipTrunk.page";
import { NfoNewCustomerPage } from "../../pages/partner/newCustomer/newCustomer.page";
import { NfoHomePage } from "../../pages/home/home.page";
import { NfoSecuritySettingsPage } from "../../pages/customer/securitySettings/securitySettings.page";
import { wholesalersEvnPage } from "../../pages/wholser/wholesalers.evn.page";
import { MainContractOrderPage } from "../../pages/order/mainContractOrder.page";
import { NfoCreateQuotePage } from "../../pages/partner/createQuote/createQuote.page";
import { NfoNewCustomerQuotePage } from "../../pages/partner/newCustomerQuote/newCustomerQuote.page";
import { NfoQuoteDetailPage } from "../../pages/partner/quoteDetail/quoteDetail.page";
import { NfoQuoteAnswerPage } from "../../pages/customer/quotes/quoteAnswer.page";
import { NfoEditQuotePage } from "../../pages/partner/editQuote/editQuote.page";

export const routes = {
  // common

  login: {
    id: `login`,
    path: `/login`,
    page: NfoLoginPage,
    needsAuthorization: false,
  } as ProjectRoute<Languages>,
  callback: {
    id: `callback`,
    path: `/callback/oauth`,
    page: NfoCallbackPage,
    needsAuthorization: false,
    exact: true,
  } as ProjectRoute<Languages>,
  setNewPassword: {
    id: `set-new-password`,
    navTitle: TRANSLATIONS.contractDetails.title,
    path: `/new-password`,
    page: NfoSetNewPasswordPage,
    needsAuthorization: false,
    exact: true,
  } as ProjectRoute<Languages>,

  // customer
  // my-contract
  customerNewMainContract: {
    path: `/customer/new-contract`,
    page: MainContractOrderPage,
    needsAuthorization: true,
    exact: true,
    visibleForRoles: [USER_CUSTOMER],
  } as ProjectRoute<Languages>,
  customerContractDetails: {
    id: `nav-link-contract-details`,
    navTitle: TRANSLATIONS.contractDetails.title,
    path: `/customer/my-contract/contract-details`,
    page: NfoContractDetailsPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER],
  } as ProjectRoute<Languages>,
  customerMyData: {
    id: `nav-link-my-data`,
    navTitle: TRANSLATIONS.myData.title,
    path: `/customer/my-contract/my-data`,
    page: NfoMyDataPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER],
  } as ProjectRoute<Languages>,
  customerBills: {
    id: `nav-link-bills`,
    navTitle: TRANSLATIONS.bills.title,
    path: `/customer/my-contract/bills`,
    page: NfoBillsPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER, USER_CUSTOMER_TELEPHONY],
  } as ProjectRoute<Languages>,
  customerItemized: {
    id: `nav-link-itemized`,
    navTitle: TRANSLATIONS.itemized.title,
    path: `/customer/my-contract/itemized`,
    page: NfoItemizedPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER, USER_CUSTOMER_TELEPHONY],
  } as ProjectRoute<Languages>,
  customerMyContract: {
    navTitle: TRANSLATIONS.myContract.title,
    path: `/customer/my-contract`,
    page: NfoMyContractPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER],
  } as ProjectRoute<Languages>,
  customerMyContracts: {
    navTitle: TRANSLATIONS.myContracts.title,
    path: `/customer/my-contracts`,
    page: NfoMyContractsPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER],
  } as ProjectRoute<Languages>,
  // settings
  customerSites: {
    id: `nav-link-sites`,
    navTitle: TRANSLATIONS.sites.title,
    path: `/customer/settings/sites`,
    page: NfoSitesPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER, USER_CUSTOMER_TELEPHONY],
  } as ProjectRoute<Languages>,
  customerSIPTrunk: {
    id: `nav-link-sites`,
    navTitle: TRANSLATIONS.sipTrunk.title,
    path: `/customer/settings/siptrunk`,
    page: NfoSipTrunkPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER],
  } as ProjectRoute<Languages>,
  customerExtensions: {
    id: `nav-link-extensions`,
    navTitle: TRANSLATIONS.settings.extensions,
    path: `/customer/settings/extensions`,
    page: NfoExtensionsPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER, USER_PARTNER],
    visibleForProducts: CENTREXX_PRODUCT_IDS,
  } as ProjectRoute<Languages>,
  customerLicenses: {
    id: `nav-link-licenses`,
    navTitle: TRANSLATIONS.licenses.title,
    path: `/customer/settings/licenses`,
    page: NfoLicensesPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER],
    visibleForProducts: CENTREXX_PRODUCT_IDS,
  } as ProjectRoute<Languages>,
  customerNumbers: {
    id: `nav-link-numbers`,
    navTitle: TRANSLATIONS.numbers.title,
    path: `/customer/settings/numbers`,
    page: NfoNumbersPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER],
  } as ProjectRoute<Languages>,
  customerPackages: {
    id: `nav-link-packages`,
    navTitle: TRANSLATIONS.packages.title,
    path: `/customer/settings/packages`,
    page: NfoPackagesPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER],
    visibleForProducts: NCONNECT_VOICE_PRODUCT_IDS,
  } as ProjectRoute<Languages>,
  customerSecuritySettings: {
    id: `nav-link-security-settings`,
    navTitle: TRANSLATIONS.securitySettings.title,
    path: `/customer/settings/security-settings`,
    page: NfoSecuritySettingsPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER],
    //visibleForProducts: NCONNECT_VOICE_PRODUCT_IDS,
  } as ProjectRoute<Languages>,
  customerFlatrate: {
    id: `nav-link-packages`,
    navTitle: TRANSLATIONS.packages.titleDTS,
    path: `/customer/settings/flatrate`,
    page: NfoFlatratePage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER],
    visibleForProducts: CENTREXX_PRODUCT_IDS,
  } as ProjectRoute<Languages>,
  customerSettings: {
    navTitle: TRANSLATIONS.settings.title,
    path: `/customer/settings`,
    page: NfoSettingsPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER],
  } as ProjectRoute<Languages>,

  // quote answer (unauthorized)
  quoteAnswer: {
    id: `auoteAnswer`,
    path: `/customer/quotes`,
    page: NfoQuoteAnswerPage,
    needsAuthorization: false,
    exact: true,
  } as ProjectRoute<Languages>,

  // profile
  customerEditProfile: {
    path: `/customer/profile`,
    page: NfoProfilePage,
    needsAuthorization: true,
  },

  // wholesalers Evn

  wholesalersEvn: {
    navTitle: TRANSLATIONS.wholesalersEvnNav.wholesaler,
    path: `/wholesalers/evn`,
    exact: true,
    page: wholesalersEvnPage,
    needsAuthorization: true,
    visibleForRoles: [USER_WHOLESALER_EVN],
  } as ProjectRoute<Languages>,


  customerTelephony: {
    id: `nav-link-my-data`,
    navTitle: TRANSLATIONS.myData.title,
    path: `/customer/my-data`,
    page: NfoMyDataPage,
    needsAuthorization: true,
    visibleForRoles: [USER_CUSTOMER_TELEPHONY],
  } as ProjectRoute<Languages>,


  // partner

  partnerCustomers: {
    navTitle: TRANSLATIONS.partnerNav.customers,
    path: `/partner/customers`,
    exact: true,
    page: NfoCustomersPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER],
  } as ProjectRoute<Languages>,
  partnerCustomersNewCustomer: {
    path: `/partner/customers/new-customer`,
    page: NfoNewCustomerPage,
    needsAuthorization: true,
    exact: true,
    visibleForRoles: [USER_PARTNER],
  } as ProjectRoute<Languages>,
  partnerCustomersNewMainContract: {
    path: `/partner/customers/new-contract`,
    page: MainContractOrderPage,
    needsAuthorization: true,
    exact: true,
    visibleForRoles: [USER_PARTNER],
  } as ProjectRoute<Languages>,
  partnerCustomersNewCustomerAndQuote: {
    path: `/partner/customers/new-customer-quote`,
    page: NfoNewCustomerQuotePage,
    needsAuthorization: true,
    exact: true,
    visibleForRoles: [USER_PARTNER],
  } as ProjectRoute<Languages>,
  partnerCustomersEditQuote: {
    path: `/partner/customers/edit-customer-quote/:quoteId/segments/:segmentId`,
    page: NfoEditQuotePage,
    needsAuthorization: true,
    exact: true,
    visibleForRoles: [USER_PARTNER],
  } as ProjectRoute<Languages>,
  partnerTasks: {
    navTitle: TRANSLATIONS.partnerNav.tasks,
    path: `/partner/tasks`,
    page: NfoTasksPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER],
  } as ProjectRoute<Languages>,

  // partner - customer sub menu
  partnerCustomersOverview: {
    path: `/partner/customer/overview`,
    page: NfoCustomersOverviewPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  partnerCustomersContract: {
    navTitle: TRANSLATIONS.partnerNav.contract,
    path: `/partner/customer/contract`,
    page: NfoCustomersContractPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  partnerCustomersContracts: {
    navTitle: TRANSLATIONS.partnerNav.contracts,
    path: `/partner/customer/contracts`,
    page: NfoMyContractsPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  partnerCustomersData: {
    navTitle: TRANSLATIONS.partnerNav.data,
    path: `/partner/customer/data`,
    page: NfoCustomersDataPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  partnerCustomerSites: {
    navTitle: TRANSLATIONS.sites.title,
    path: `/partner/customer/sites`,
    page: NfoSitesPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  partnerCustomerNumbers: {
    navTitle: TRANSLATIONS.numbers.title,
    path: `/partner/customer/numbers`,
    page: NfoNumbersPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  partnerCustomerSIPTrunk: {
    navTitle: TRANSLATIONS.sipTrunk.title,
    path: `/partner/customer/siptrunk`,
    page: NfoSipTrunkPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  partnerCustomerPackages: {
    navTitle: TRANSLATIONS.packages.title,
    path: `/partner/customer/packages`,
    page: NfoPackagesPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
    visibleForProducts: NCONNECT_VOICE_PRODUCT_IDS,
  } as ProjectRoute<Languages>,
  partnerCustomerFlatrate: {
    navTitle: TRANSLATIONS.packages.titleDTS,
    path: `/partner/customer/flatrate`,
    page: NfoFlatratePage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
    visibleForProducts: CENTREXX_PRODUCT_IDS,
  } as ProjectRoute<Languages>,
  partnerCustomerLicenses: {
    navTitle: TRANSLATIONS.licenses.title,
    path: `/partner/customer/licenses`,
    page: NfoLicensesPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
    visibleForProducts: CENTREXX_PRODUCT_IDS,
  } as ProjectRoute<Languages>,
  partnerCustomerExtensions: {
    navTitle: TRANSLATIONS.settings.extensions,
    path: `/partner/customer/extensions`,
    page: NfoExtensionsPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
    visibleForProducts: CENTREXX_PRODUCT_IDS,
  } as ProjectRoute<Languages>,
  partnerCustomerSecuritySettings: {
    navTitle: TRANSLATIONS.securitySettings.title,
    path: `/partner/customer/security-settings`,
    page: NfoSecuritySettingsPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER, USER_WHOLESALER],
    visibleForProducts: CENTREXX_PRODUCT_IDS,
  } as ProjectRoute<Languages>,
  partnerMyAccount: {
    navTitle: TRANSLATIONS.partnerNav.contactHistory,
    path: `/partner/my-account`,
    page: NfoMyAccountPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER],
  } as ProjectRoute<Languages>,
  partnerCreateQuote: {
    navTitle: TRANSLATIONS.partnerNav.createQuote,
    path: `/partner/create-quote`,
    page: NfoCreateQuotePage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER],
  } as ProjectRoute<Languages>,
  partnerQuoteDetail: {
    navTitle: TRANSLATIONS.partnerNav.createQuote,
    path: `/partner/quote/detail/:quoteId`,
    page: NfoQuoteDetailPage,
    needsAuthorization: true,
    visibleForRoles: [USER_PARTNER],
  } as ProjectRoute<Languages>,
  // Wholesaler

  wholesalerCustomers: {
    navTitle: TRANSLATIONS.partnerNav.customers,
    path: `/partner/customers`,
    page: NfoCustomersPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  wholesalerTasks: {
    navTitle: TRANSLATIONS.partnerNav.tasks,
    path: `/partner/tasks`,
    page: NfoTasksPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_WHOLESALER],
  } as ProjectRoute<Languages>,

  // partner - customer sub menu
  wholesalerCustomersOverview: {
    path: `/partner/customer/overview`,
    page: NfoCustomersOverviewPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  wholesalerCustomersContract: {
    navTitle: TRANSLATIONS.partnerNav.contract,
    path: `/partner/customer/contract`,
    page: NfoCustomersContractPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  wholesalerCustomersData: {
    navTitle: TRANSLATIONS.partnerNav.data,
    path: `/partner/customer/data`,
    page: NfoCustomersDataPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  wholesalerCustomersOrders: {
    navTitle: TRANSLATIONS.partnerNav.orders,
    path: `/partner/customer/orders`,
    page: NfoCustomersOrdersPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  wholesalerCustomersTasks: {
    navTitle: TRANSLATIONS.partnerNav.tasks,
    path: `/partner/customer/tasks`,
    page: NfoCustomersTasksPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  wholesalerCustomersContactHistory: {
    navTitle: TRANSLATIONS.partnerNav.contactHistory,
    path: `/partner/customer/contact-history`,
    page: NfoCustomersContactHistoryPartnerPage,
    needsAuthorization: true,
    visibleForRoles: [USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  wholesalerMyAccount: {
    navTitle: TRANSLATIONS.partnerNav.contactHistory,
    path: `/partner/my-account`,
    page: NfoMyAccountPage,
    needsAuthorization: true,
    visibleForRoles: [USER_WHOLESALER],
  } as ProjectRoute<Languages>,
  home: {
    id: `home`,
    path: `/`,
    page: NfoHomePage,
    needsAuthorization: true,
  } as ProjectRoute<Languages>,
};

export type Routes = typeof routes;
