import commonStyles from "../../../../../sass/base/common.module.scss";
import React from "react";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { CpxDropdownWithLabel } from "../../../../../../core/components/dropdown.component";
import { CpxInputWithLabel } from "../../../../../../core/components/inputWithLabel.component";
import { CpxSwitch } from "../../../../../../core/components/switch.component";
import "./BillingInformation.scss";
import { CpxAddressInputGroup } from "../../../../../../core/components/addressInputGroup.component";
import { CpxIbanInputWithLabel } from "../../../../../../core/components/ibanInputWithLabel.component";
import { CpxHelpBox } from "../../../../../../core/components/helpBox.component";
import { PopupDirections, SelectOption } from "../../../../../constants/types/types.constants";
import { ID_SALUTATIONS } from "../../../../../constants/configs/config.constants";
import { useTheme } from "../../../../../../core/utility/themeContext";


export const BillingInformation = (
  {
    values, handleChange, touched, errors, paymentOptions, billingMediumOptions, salutationOptions, countryOptions, selectedCountry, setSelectedCountry, handleSalutation,
    isSEPA,
    setIsSEPA,
    isDifferentBillingAddress,
    setIsDifferentBillingAddress,
    stepClassName,
  }: any) => {
  const theme = useTheme();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const internalClassName = "billingInformation";
  const translationsCommon = useTranslations(TRANSLATIONS.common);

  const DEFAULT_SELECT_OPTION: SelectOption = {
    id: '',
    name: translationsCommon.defaultSelect(),
  }

  const onChangePaymentMode = (e: any) => {
    handleChange(e);
    if(e.target.value === "1"){
      setIsSEPA(true);
    } else if(e.target.value === "2") {
      setIsSEPA(false);
    }
  }

  const inputNames = {
    country: "account.billAddress.country.id",
    houseNumber: "account.billAddress.houseNumber",
    street: "account.billAddress.street",
    additionalAddress: "account.billAddress.additionalAddress",
    zip: "account.billAddress.zip",
    city: "account.billAddress.city",
    city2: "account.billAddress.city2",
    city3: "account.billAddress.city3",
    district: "account.billAddress.district",
    county: "account.billAddress.county",
    province: "account.billAddress.province",
  }

  return (
    <div className={commonStyles.columnContainer}>
      <div className={commonStyles.column}>
        <div className={stepClassName}>
          <h4 className={`step-subtitle-le--${theme}`}>
            {translation.billingInformation()}
          </h4>
          <div className={`${internalClassName}-container`}>
            <div className={`${internalClassName}-ibanInputGroup`}>
              <CpxDropdownWithLabel
                id="account.paymentMode.id"
                name="account.paymentMode.id"
                defaultOption={values.account?.paymentMode?.id}
                options={paymentOptions}
                value={values.account?.paymentMode?.id}
                labelClassName={"label-helpBox"}
                onChange={handleChange}
                error={errors?.account?.paymentMode?.id}
                disabled
              >
                <div className={'input-helpBox'}>
                  <CpxHelpBox className={"helpBox"} popupDirection={PopupDirections.RIGHT}>
                    {translation.paymentOptionInfoBoxText()}
                  </CpxHelpBox>
                  {translation.paymentOption() + ' *'}
                </div>
              </CpxDropdownWithLabel>
            </div>
            <CpxDropdownWithLabel
              id="account.paymentMedia.id"
              name="account.paymentMedia.id"
              defaultOption={DEFAULT_SELECT_OPTION}
              options={billingMediumOptions}
              value={values.account?.paymentMedia?.id}
              labelClassName={"label-helpBox"}
              onChange={handleChange}
              error={errors?.account?.paymentMedia?.id}
              required
            >
              {translation.billingMedium()}
            </CpxDropdownWithLabel>
            {isSEPA && (
              <>
                <CpxInputWithLabel
                  id="account.accountName"
                  type="text"
                  onChange={handleChange}
                  value={values?.account?.accountName.trimStart()}
                  error={touched?.account?.accountName && errors?.account?.accountName}
                  required={isSEPA}
                >
                  {translation.accountOwner()}
                </CpxInputWithLabel>
                <CpxIbanInputWithLabel
                  id="account.iban"
                  type="text"
                  onChange={handleChange}
                  value={values?.account?.iban.trimStart()}
                  error={touched?.account?.iban && errors?.account?.iban}
                  required={isSEPA}
                >
                  {translation.iban()}
                </CpxIbanInputWithLabel>
                <CpxInputWithLabel
                  id="account.bic"
                  type="text"
                  onChange={handleChange}
                  value={values?.account?.bic.trimStart()}
                  error={touched?.account?.bic && errors?.account?.bic}
                  required={isSEPA}
                >
                  {translation.bic()}
                </CpxInputWithLabel>
              </>
            )}
          </div>
          <p className={`${internalClassName}-switch--wrapper`}>
            <CpxSwitch
              className={`${internalClassName}-switch`}
              onChange={() => setIsDifferentBillingAddress(!isDifferentBillingAddress)}
              checked={isDifferentBillingAddress}
            />
            {translation.differentBillingAddress()}
          </p>
          {(!isNaN(isDifferentBillingAddress) && isDifferentBillingAddress) && <div className={`${internalClassName}-collapsible`}>
            <CpxDropdownWithLabel
              id="account.billAddress.salutation.id"
              name="account.billAddress.salutation.id"
              required={isDifferentBillingAddress}
              options={salutationOptions}
              defaultOption={DEFAULT_SELECT_OPTION}
              onChange={handleSalutation}
              value={values?.account?.billAddress?.salutation?.id}
              error={errors?.account?.billAddress?.salutation?.id}
            >
              {translation.salutation()}
            </CpxDropdownWithLabel>
            <CpxInputWithLabel
              id="account.billAddress.companyName1"
              type="text"
              onChange={handleChange}
              value={values?.account?.billAddress?.companyName1.trimStart()}
              error={touched?.account?.billAddress?.companyName1 && errors?.account?.billAddress?.companyName1}
              required={isDifferentBillingAddress}
            >
              {values?.account?.billAddress?.salutation?.id &&
              (parseInt(values?.account?.billAddress?.salutation?.id) === ID_SALUTATIONS.COMPANY) ?
                translation.companyName1() : translation.firstName()}
            </CpxInputWithLabel>
            <CpxInputWithLabel
              id="account.billAddress.companyName2"
              type="text"
              onChange={handleChange}
              value={values?.account?.billAddress?.companyName2.trimStart()}
              error={touched?.account?.billAddress?.companyName2 && errors?.account?.billAddress?.companyName2}
            >
              {values?.account?.billAddress?.salutation?.id &&
              (parseInt(values?.account?.billAddress?.salutation?.id) === ID_SALUTATIONS.COMPANY) ?
                translation.companyName2() : translation.lastName() + ' *'}
            </CpxInputWithLabel>
            <CpxAddressInputGroup
              values={values?.account?.billAddress}
              touched={touched?.account?.billAddress}
              errors={errors?.account?.billAddress}
              inputNames={inputNames}
              countryOptions={countryOptions}
              handleChange={handleChange}
              popupDirection={PopupDirections.RIGHT}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />
            <CpxInputWithLabel
              id="account.billAddress.email"
              type="text"
              onChange={handleChange}
              value={values?.account?.billAddress?.email.trimStart()}
              error={touched?.account?.billAddress?.email && errors?.account?.billAddress?.email}
            >
              {translation.billingEmail()}
            </CpxInputWithLabel>
          </div>}
        </div>
      </div>
    </div>
  )
}