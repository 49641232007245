import "./collapsiblePanel.scss";
import React, { useState } from "react";
import cs from "classnames";
import { CpxIcon } from "../../../../core/components/icon.component";
import { ICONS } from "../../../constants/configs/config.constants";


type Props = {
  children: React.ReactNode;
  title: string;
  initialState?: boolean;
  icon?: string;
}

export const NfoCollapsiblePanel = ({ children, title, icon, initialState = false }: Props) => {

  const internalClassName = "panel";

  const [contentVisible, setContentVisible] = useState(initialState);

  const togglePanel = () => {
    setContentVisible((x: boolean) => !x)
  }

  const contentRef = React.useRef<HTMLDivElement>(null as any);

  return (
    <div className={cs(internalClassName)}>
      <div className={cs(`${internalClassName}-header`)} onClick={(e) => togglePanel()}>
        <h5>{title}</h5>
        <CpxIcon className={"pointer"} icon={contentVisible ? ICONS.ARROW_UP : ICONS.ARROW_DOWN}/>
        {icon && <CpxIcon icon={icon}/>}
      </div>
      <div
        ref={contentRef}
        className={cs(`${internalClassName}-content`)}
        style={{
          height: (contentVisible && contentRef.current) ? `${contentRef.current.scrollHeight}px` : '0',
          overflow: 'hidden',
          transition: 'height 0.2s ease-out'
        }}>
        {children}
      </div>
    </div>
  );
};
