import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { CpxTitle } from "../../../../core/components/title/title.component";
import "./createQuote.scss";
import React, { useEffect, useState } from "react";
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ID_DATA_ENTITY, ID_STATUS_QUOTES, ORDER_TYPE } from "../../../constants/configs/config.constants";
import { CpxButton } from "../../../../core/components/button.component";
import { SearchBox } from "../../../components/common/search/searchBox.component";
import cs from "classnames";
import { CpxPaginatedTable } from "../../../../core/components/paginatedTable.component";
import { NfoEmptyState } from "../../../../core/emptyState.component";
import { usePartnerQuotes, useSelectLang } from "../../../redux/store";
import { Quote } from "compax-api";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useTheme } from "../../../../core/utility/themeContext";
import { formatDate } from "../../../../core/utils";
import { NfoStatus } from "../../../../core/components/status.component";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import { CpxDropdownWithLabel } from "../../../../core/components/dropdown.component";
import { SelectOption } from "../../../constants/types/types.constants";
import { deleteStepData, saveCurrentOrderType } from "../../../redux/actions/step.action";
import { routes } from "../../../constants/routes/routes";
import { useHistory } from "react-router-dom";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoCreateQuotePage = () => {
  const dispatch: AppDispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.createQuote);
  const translationsStatus = useTranslations(TRANSLATIONS.status.quote);
  const internalClassName = "createQuote";
  const partnerQuotes = usePartnerQuotes() as Array<Quote>;
  const theme = useTheme();
  const language = useSelectLang().selected;
  const [statusFilteredQuotes, setStatusFilteredQuotes] = useState(partnerQuotes);
  const [filteredItems, setFilteredItems] = useState(statusFilteredQuotes);
  const history = useHistory();
  const getFilteredItems = (data: any) => {
    setFilteredItems(data);
  };

  useEffect(() => {
    setStatusFilteredQuotes(partnerQuotes);
    setFilteredItems(partnerQuotes)
  }, [partnerQuotes])


  const quoteStates = [
    { id: ID_STATUS_QUOTES.NEW, name: translationsStatus.new()},
    { id: ID_STATUS_QUOTES.READY_FOR_SUBMISSION, name: translationsStatus.readyForSubmission()},
    { id: ID_STATUS_QUOTES.ACCEPTED_BY_CUSTOMER, name: translationsStatus.acceptedByCustomer()},
    { id: ID_STATUS_QUOTES.CONTRACT_DOCUMENT_SENT, name: translationsStatus.contractDocumentSent()},
    { id: ID_STATUS_QUOTES.START_ORDER_PROCESS, name: translationsStatus.orderProcessStarted()},
    { id: ID_STATUS_QUOTES.ORDERED, name: translationsStatus.ordered()},
    { id: ID_STATUS_QUOTES.REFUSED_BY_CUSTOMER, name: translationsStatus.refusedByCustomer()},
    { id: ID_STATUS_QUOTES.CANCELED, name: translationsStatus.canceled()},
  ]

  const DEFAULT_SELECT_OPTION: SelectOption = {
    id: -1,
    name: translations.all(),
  }

  const attributesToBeSearched = ['customer.customerId', 'customer.shortName', 'quoteIdVersion'];

  const onChangeFilterStatus = (e: any) => {
    let selectedId: number = +e.target.value;
    if(selectedId !== -1) {
      setStatusFilteredQuotes(partnerQuotes.filter(quote => quote.status?.id === selectedId))
    }else{
      setStatusFilteredQuotes(partnerQuotes);
    }
  };

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNER_QUOTES));
  }, [])

  const tableHeader = [
    { label: translations.tableHeaderCustomer(), sort: true, sortBy: 'customer.customerId', sortMethod: 'number' },
    { label: translations.tableHeaderCustomerId(), sort: true, sortBy: 'customer.shortName', sortMethod: 'string' },
    { label: translations.tableHeaderQuoteId(), sort: true, sortBy: 'id', sortMethod: 'number' },
    { label: translations.tableHeaderCreateDt(), sort: true, sortBy: 'createDate', sortMethod: 'date' },
    { label: translations.tableHeaderStatus(), sort: true, sortBy: 'status.id', sortMethod: 'number' },
  ]

  const onClickPartnerQuote = (e: React.MouseEvent<HTMLAnchorElement>, partnerQuote: Quote) => {
    history.push(routes.partnerQuoteDetail.path.replace(":quoteId", `${partnerQuote.id}`));
  }

  const customerTableData = (partnerQuote: Quote) => {
    return {
      id: '' + partnerQuote?.id,
      cellData: [
        <div
          className={cs('tableFirstChild', `tableFirstChild-font-le--${theme}`)}
        >
          <a
            className={cs(
              'partnerCustomerButton',
              `partnerCustomerButton-font-le--${theme}`
            )}
            onClick={e => onClickPartnerQuote(e, partnerQuote)}
          >
            {partnerQuote.customer?.shortName}
          </a>

        </div>,
        <div>
          {partnerQuote.customer?.customerId}
        </div>,
        <div>
          {partnerQuote.quoteIdVersion}
        </div>,
        <div>
          {formatDate(new Date(partnerQuote.createDate as Date), language)}
        </div>,
        <NfoStatus
          entity={{ id: ID_DATA_ENTITY.QUOTE }}
          status={{ id: partnerQuote.status?.id !== undefined ? partnerQuote.status.id : 0 }}
        />,
      ],
    };
  };



  return (
    <div className={internalClassName}>
      <div className={cs('quotesTitle')}>
        <CpxTitle
          title={translations.title()}
          internalClassName={internalClassName}
        />
        <div className={"buttonGroup"}>
          <CpxButton
            className={"buttonIcons"}
            onClick={() => {
              dispatch(deleteStepData());
              dispatch(saveCurrentOrderType(ORDER_TYPE.ORDER_NEW_CUSTOMER_QUOTE));
              history.push(routes.partnerCustomersNewCustomerAndQuote.path);
            }}
          >
            <Add/>
            {translations.addQuote()}
          </CpxButton>
        </div>
      </div>
      <div className={'subTitleBox'}>
        <CpxSubtitle
          subtitle={translations.subheading()}
          internalClassName={'internalClassName'}
        />
      </div>

      <NfoEmptyState
        data={partnerQuotes}
        pageTitle={translations.title()}
        subtitle={translations.emptyStateSubTitle()}
        boxStyling={true}
      >
        {partnerQuotes && (
          <>
            <SearchBox
              theme={theme}
              placeholder={
                translations.tableHeaderCustomer() +
                ', ' +
                translations.tableHeaderCustomerId() +
                ', ' +
                translations.tableHeaderQuoteId() +
                ' ...'
              }
              id={'partnerQuotesTable'}
              data={statusFilteredQuotes && statusFilteredQuotes}
              getFilteredItems={getFilteredItems}
              shouldBeSearched={attributesToBeSearched}
            >
              <CpxDropdownWithLabel
                id={'currentStatus.id'}
                name={`currentStatus.id`}
                defaultOption={DEFAULT_SELECT_OPTION}
                onChange={onChangeFilterStatus}
                options={quoteStates}
              >
                {'Status'}
              </CpxDropdownWithLabel>
            </SearchBox>


            {filteredItems && filteredItems instanceof Array && (
              <article className={cs(`customers`, `customers-le--${theme}`)}>
                <CpxPaginatedTable
                  id="quotesTable"
                  tableHeader={tableHeader}
                  tableData={filteredItems.map(customerTableData)}
                  itemsPerPage={[10, 20]}
                  pagination="both"
                  itemsPerPageLabel={translations.paginationSelectLabel()}
                  sliceToString={(from: number, to: number, total: number) =>
                    translations.paginationSliceInfo(
                      from + '',
                      to + '',
                      total + ''
                    )
                  }
                  rowData={filteredItems && filteredItems}
                  getSortedRowsBack={(sortedRows: any) => setFilteredItems(sortedRows?.slice())}
                />
              </article>
            )}
          </>
        )}
      </NfoEmptyState>

    </div>
  );
};
