import { Account, Address, CommunicationHistory, ContactData, ContactPerson, ExtensionsContractInfo, NfonCliInformation, PortalBill, PortalContractChargesSum, PortalContractData, PortalPartnerData, Quote, WorkFlowInfo } from 'compax-api';

export type SelectOption = {
    id: number | string;
    name: string;
    data?: any;
}

export type AreaCodeError = {
    index: number,
    message: string,
}

export interface IAction {
    type: string;
    data?: any;
}

export interface IPartnerCustomers {
    errorCode?: number;
    errorData?: string;
    partnerCustomers?: [];
    // [index: number]: any
}

export interface IPartnerCustomer {
    salutation?: {
        id: number;
        description?: string;
    };
    id: number;
    customerId?: string;
    companyName?: string;
    firstName?: string;
    lastName?: string;
    status?: {
        id: number;
        description?: string;
    };
    createDate?: string;
    mainAddress?: Address;
    mainContact?: ContactData;
    contactPersons?: ContactPerson[];
}

export interface IPartnerCustomerContracts
  extends Array<PortalContractData> {
}


export interface ISelectedPartnerCustomer {
    partnerCustomer?: IPartnerCustomer;
    accounts?: IAccounts;
    contract?: PortalContractData;
    extensions?: ExtensionsContractInfo;
    menuItems?: Object[];
    numbers?: any;
    address?: any;
    chargeSum?: PortalContractChargesSum;
    communications?: CommunicationHistory[];
    workflowsinfo?: WorkFlowInfo;
}

export interface IPartnerCustomerReducer {
    partnerCustomers?: IPartnerCustomers;
    partnerQuotes?: Array<Quote>;
    selectedPartnerCustomer?: ISelectedPartnerCustomer;
}

export enum PopupDirections {
    LEFT = "left",
    RIGHT = "right",
}

export enum ToastTypes {
    SUCCESS = "success",
    ERROR = "error",
}

export enum ProductOptionModalType {
    QUANTITY = "QUANTITY",
    CUSTOM_DATA = "CUSTOM_DATA",
    TELCO_DATA = "TELCO_DATA",
    EDIT_TELCO_DATA = "EDIT_TELCO_DATA",
}

export enum DocumentEventType {
    ONLOAD = "ONLOAD",
    ADD = "ADD",
    DELETE = "DELETE",
}
export interface IDocument {
    label: string;
    type: string | null;
    fileName: any;
    documentId?: any;
    uploaded: boolean;
    documentTypeId?: number;
    entries: [string] | [];
}

export interface IAccounts extends Array<Account> {
}

export interface IContracts extends Array<PortalContractData> {
}

export interface IBills extends Array<PortalBill> {
}

export interface INfonCliInformations extends Array<NfonCliInformation> {
}

export interface IAddresses extends Array<Address> {
}

export interface IPartners extends Array<PortalPartnerData> {
}


